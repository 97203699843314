import { useEffect, useState } from "react";
import useBlockchainContext from "../data/useBlockchainContext";
import useDataContext from "../data/useDataContext";
import Loading from "../components/Loading";

const Comissions = () => {
    const { collections, showErrorMessage, startProcessing, showSuccessMessage, isOwner } = useDataContext();
    const {
        getCurrentReferralFee,
        getCurrentMarketplaceFee,
        setReferralFee,
        setMarketplaceFee,
        getDexArtMarketplaceInfo,
        setDexArtMarketplaceAletrnoFee,
        setDexArtMarketplaceMaxFee,
    } = useBlockchainContext();
    const [collectionsInfo, setCollectionsInfo] = useState([]);
    const [dexArtMarketplaceInfo, setDexArtMarketplaceInfo] = useState(undefined);

    useEffect(() => {
        if (!isOwner) return;
        if (collections.length > 0) processCollections();
        getDexArtMarketplaceInfo().then((val) => setDexArtMarketplaceInfo(val));
    }, [collections]);

    async function processCollections() {
        try {
            const updated = [...collections];
            for (let i = 0; i < updated.length; i++) {
                const collection = updated[i];
                const referralFee = await getCurrentReferralFee(collection.address, collection.slug);
                collection.referralFee = referralFee;
                if (collection.slug !== "alterno") {
                    collection.marketplaceFee = await getCurrentMarketplaceFee(collection.address, collection.slug);
                }
            }
            setCollectionsInfo(updated);
        } catch (err) {
            showErrorMessage(err.message);
        }
    }

    async function handleReferralChange(slug, address) {
        try {
            startProcessing("Please approve the transaction...");
            const input = document.getElementById(slug + "-ref");
            const bps = parseInt(parseFloat(input.value) * 100);
            if (bps > 10000) throw new Error("Percentage cannot be greater than 100");
            await setReferralFee(address, slug, bps);
            showSuccessMessage(`Referral fee for ${slug} collection changed successfully!`);
        } catch (err) {
            showErrorMessage(err.message);
            return;
        }
    }

    async function handlePartnerMarketplaceChange(slug, address) {
        try {
            startProcessing("Please approve the transaction...");
            const input = document.getElementById(slug + "-mark");
            const bps = parseInt(parseFloat(input.value) * 100);
            if (bps > 10000) throw new Error("Percentage cannot be greater than 100");
            await setMarketplaceFee(address, slug, bps);
            showSuccessMessage(`Marketplace fee for ${slug} collection changed successfully!`);
        } catch (err) {
            showErrorMessage(err.message);
            return;
        }
    }

    async function handleDexartMarketplaceChange() {
        try {
            startProcessing("Please approve the transaction...");
            const input = document.getElementById("marketplace");
            const bps = parseInt(parseFloat(input.value) * 100);
            if (bps > 10000) throw new Error("Percentage cannot be greater than 100");
            await setDexArtMarketplaceAletrnoFee(bps);
            showSuccessMessage(`Alterno fee for DexArt Marketplace changed successfully!`);
        } catch (err) {
            showErrorMessage(err.message);
            return;
        }
    }
    async function handleDexartMaxChange() {
        try {
            startProcessing("Please approve the transaction...");
            const input = document.getElementById("maxFee");
            const bps = parseInt(parseFloat(input.value) * 100);
            if (bps > 10000) throw new Error("Percentage cannot be greater than 100");
            await setDexArtMarketplaceMaxFee(bps);
            showSuccessMessage(`Max fee for DexArt Marketplace changed successfully!`);
        } catch (err) {
            showErrorMessage(err.message);
            return;
        }
    }

    if (!isOwner)
        return (
            <div className="section">
                <h3>You do not have permission to edit</h3>
            </div>
        );

    return (
        <div className="comissions">
            {collectionsInfo.length > 0 ? (
                collectionsInfo.map((coll, index) => {
                    return (
                        <div className="section" key={index}>
                            <h4>{coll.name}</h4>
                            <div className="comissions-form">
                                <div className="input-group">
                                    <label htmlFor={coll.slug + "-ref"}>Referral fee</label>
                                    <input
                                        type="number"
                                        id={coll.slug + "-ref"}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        name={coll.slug + "-ref"}
                                        defaultValue={(coll.referralFee / 100).toFixed(2)}
                                    />
                                    <button onClick={() => handleReferralChange(coll.slug, coll.address)}>Change</button>
                                </div>
                                {coll.slug !== "alterno" && (
                                    <div className="input-group">
                                        <label htmlFor={coll.slug + "-mark"}>Marketplace fee</label>
                                        <input
                                            type="number"
                                            id={coll.slug + "-mark"}
                                            min={0}
                                            max={100}
                                            step={0.01}
                                            name={coll.slug + "-mark"}
                                            defaultValue={(coll.marketplaceFee / 100).toFixed(2)}
                                        />
                                        <button onClick={() => handlePartnerMarketplaceChange(coll.slug, coll.address)}>Change</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })
            ) : (
                <Loading />
            )}
            <div className="section">
                <h4>DexArt Marketplace</h4>
                {dexArtMarketplaceInfo === undefined ? (
                    <Loading />
                ) : dexArtMarketplaceInfo === null ? (
                    <p>Error loading DexArt data</p>
                ) : (
                    <div className="comissions-form">
                        <div className="input-group">
                            <label htmlFor="marketplace">Alterno fee</label>
                            <input
                                type="number"
                                id="marketplace"
                                min={0}
                                max={100}
                                step={0.01}
                                name="marketplace"
                                defaultValue={(dexArtMarketplaceInfo.marketplaceFee.toNumber() / 100).toFixed(2)}
                            />
                            <button onClick={handleDexartMarketplaceChange}>Change</button>
                        </div>
                        <div className="input-group">
                            <label htmlFor="maxFee">Max fee</label>
                            <input
                                type="number"
                                id="maxFee"
                                min={0}
                                max={100}
                                step={0.01}
                                name="maxFee"
                                defaultValue={(dexArtMarketplaceInfo.maxFee.toNumber() / 100).toFixed(2)}
                            />
                            <button onClick={handleDexartMaxChange}>Change</button>
                        </div>
                        <div className="input-group">
                            <label htmlFor="creatorFee">DexArt fee</label>
                            <input
                                type="number"
                                id="creatorFee"
                                min={0}
                                max={100}
                                step={0.01}
                                name="creatorFee"
                                defaultValue={(dexArtMarketplaceInfo.creatorFee.toNumber() / 100).toFixed(2)}
                                readOnly
                            />
                            <button disabled>Change</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Comissions;
