import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useBlockchainContext from "../data/useBlockchainContext";
import useDataContext from "../data/useDataContext";

const Login = () => {
    const { connectWallet, signMessage } = useBlockchainContext();
    const { getLoginNonce, handleLogin, loggedIn, showErrorMessage } = useDataContext();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (loggedIn) {
            navigate(location.state ? location.state.from : "/nfts");
        }
    });

    async function handleLoginEvent(e) {
        e.preventDefault();
        try {
            const walletAddress = await connectWallet();
            const nonce = await getLoginNonce(walletAddress);
            const signedMessage = await signMessage(nonce);
            const result = await handleLogin(walletAddress, signedMessage);
            if (result) {
                navigate(location.state ? location.state.from : "/nfts");
            }
        } catch (err) {
            if (err.code === 4001) {
                showErrorMessage("You have rejected the request");
            } else {
                showErrorMessage(err.message);
            }
        }
    }

    return (
        <div className="login-section">
            <div>
                <h1>Login</h1>
                <small>Connect your wallet and sign a message</small>
            </div>
            <button type="submit" onClick={handleLoginEvent} className="btn-submit btn-lg">
                Connect Wallet
            </button>
        </div>
    );
};

export default Login;
