import { useRef } from "react";
import useDataContext from "../data/useDataContext";
import useBlockchainContext from "../data/useBlockchainContext";
import { Link } from "react-router-dom";
import { formatAddressString } from "../utils/helpers";
import UpdateWebsite from "../components/UpdateWebsite";

import IconButton from "../components/IconButton";

import { ReactComponent as AddIcon } from "../assets/icons/plus-solid.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-can-solid.svg";

const Settings = () => {
    const { adminWallets, deleteAdminWallet, startProcessing, showSuccessMessage, showErrorMessage, cookiesText, editCookiesText, isOwner, account } =
        useDataContext();
    const { removeEditor } = useBlockchainContext();
    const headingInput = useRef();
    const textInput = useRef();

    async function handleAdminDelete(event, id, address) {
        event.preventDefault();
        try {
            if (typeof id != "string") throw new Error("Incorrect id value");
            if (!isOwner) throw new Error("Not authorized");
            startProcessing("Updating blockchain info");
            await removeEditor(address);
            startProcessing("Updating database info");
            await deleteAdminWallet(id);
            showSuccessMessage("Admin wallet deleted successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            if (err.code) {
                ///Metamask error
                if (err.code === "ACTION_REJECTED") return showErrorMessage("You have rejected transaction");
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function handleUpdateCookiesText(event) {
        event.preventDefault();
        try {
            startProcessing("Updating database info");
            const result = await editCookiesText(cookiesText._id, headingInput.current.value, textInput.current.value);
            if (result) showSuccessMessage("Cookies text updated successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section" id="settings-section">
            <h1>Settings</h1>
            <UpdateWebsite />
            {isOwner && (
                <div className="section">
                    <div className="section-header">
                        <h2>Admin wallets</h2>
                        <Link to="/wallets/add">
                            <IconButton addClass="btn-submit" text={"add new"} icon={<AddIcon />} />
                        </Link>
                    </div>
                    <ol>
                        {adminWallets.map((wallet, index) => {
                            return (
                                <li key={index} className="item-container">
                                    <div>
                                        <h3>{window.innerWidth < 500 ? formatAddressString(wallet.wallet) : wallet.wallet}</h3>
                                    </div>
                                    {wallet.wallet !== account && (
                                        <div className="buttons-container">
                                            <IconButton
                                                addClass="btn-danger"
                                                icon={<TrashIcon />}
                                                text="delete"
                                                customClickEvent={(event) => handleAdminDelete(event, wallet._id, wallet.wallet)}
                                            />
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ol>
                </div>
            )}
            <div className="section">
                <h2>Cookies Text</h2>
                <div className="input-group">
                    <input type="text" id="cookies-heading" ref={headingInput} defaultValue={cookiesText ? cookiesText.heading : ""} />
                    <label htmlFor="cookies-heading">Heading text</label>
                </div>
                <div className="input-group">
                    <input type="text" id="cookies-text" ref={textInput} defaultValue={cookiesText ? cookiesText.text : ""} />
                    <label htmlFor="cookies-text">Banner text</label>
                </div>
                <button onClick={handleUpdateCookiesText}>Update</button>
            </div>
            <div className="section">
                <h2>Footer</h2>
                <div className="input-group">
                    <h5>Footer Menu</h5>
                    <Link to="footer-menu">
                        <button>Edit</button>
                    </Link>
                </div>
                <div className="input-group">
                    <h5>Footer Social Links</h5>
                    <Link to="socials">
                        <button>Edit</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Settings;
