import { Link } from "react-router-dom";

import useDataContext from "../data/useDataContext";
import IconButton from "./IconButton";
import { ReactComponent as EnabledIcon } from "../assets/icons/toggle-on-solid.svg";
import { ReactComponent as DisabledIcon } from "../assets/icons/toggle-off-solid.svg";
import { ReactComponent as SaveIcon } from "../assets/icons/floppy-disk-solid.svg";

const EditSocials = () => {
    const { socials, editSocialItem, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();

    async function handleActiveFlip(e, id, link, currentState) {
        e.preventDefault();
        try {
            startProcessing("Updating database information");
            await editSocialItem(id, link, !currentState);
            showSuccessMessage("Link status has been changed successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function handleLinkChange(e, id, currentState) {
        e.preventDefault();
        const input = document.getElementById(id);
        try {
            startProcessing("Updating database information");
            await editSocialItem(id, input.value, currentState);
            showSuccessMessage("Link address has been changed successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/settings">
                    <button>Back</button>
                </Link>
                <h1>Edit Social Links</h1>
            </div>
            <div className="subsection-body">
                <div className="socials">
                    {socials.map((item) => {
                        return (
                            <div key={item._id}>
                                <h5>{item.name}</h5>
                                <input type="text" id={item._id} defaultValue={item.link} />
                                <IconButton
                                    addClass="btn-normal btn-no-bg"
                                    icon={<SaveIcon />}
                                    text="Update Link"
                                    customClickEvent={(e) => handleLinkChange(e, item._id, item.active)}
                                />
                                <IconButton
                                    addClass={item.active ? "btn-submit btn-no-bg" : "btn-normal btn-no-bg"}
                                    icon={item.active ? <EnabledIcon /> : <DisabledIcon />}
                                    text={item.active ? "Disable" : "Enable"}
                                    customClickEvent={(e) => handleActiveFlip(e, item._id, item.link, item.active)}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EditSocials;
