import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import useDataContext from "../data/useDataContext";
import useBlockchainContext from "../data/useBlockchainContext";

const MultiUpload = () => {
    const { parseCSVFile, preUploadNFTs, uploadNFTs, startProcessing, setModalMessage, showSuccessMessage, showErrorMessage } = useDataContext();
    const { getTokenSupply, addMultipleNFTToContract } = useBlockchainContext();
    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    function handleFileChange(event) {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === "text/csv") {
            setFile(selectedFile);
        } else {
            setFile(null);
        }
    }

    async function handleFormSubmit(event) {
        event.preventDefault();
        try {
            if (file) {
                const startTokenId = await getTokenSupply();
                ///Parse file
                startProcessing("Parsing file");
                const parsedData = await parseCSVFile(file);
                ///Preupload file to express
                setModalMessage("Processing SketchFab data");
                const preUploadResult = await preUploadNFTs(parsedData, startTokenId);
                ///Receive final data
                if (preUploadResult.success.length > 0) {
                    ///Add to blockchain
                    setModalMessage("Waiting for blockchain confirmation");
                    const blockchainResult = await addMultipleNFTToContract(preUploadResult.success);
                    if (!blockchainResult) throw new Error("Error during blockchain interaction");

                    ///Add to database
                    setModalMessage("Adding to database");
                    const uploadResult = await uploadNFTs(preUploadResult.success);
                    if (preUploadResult.errors.length > 0) {
                        const addInfo = preUploadResult.errors.map((error, index) => (
                            <div key={index}>
                                <h5>{error.sketchFabId}:</h5>
                                <small>{error.message}</small>
                            </div>
                        ));
                        showSuccessMessage(
                            `${uploadResult.length} NFTs added successfully. ${preUploadResult.errors.length} Errors were mitigated:`,
                            addInfo
                        );
                    } else {
                        showSuccessMessage(`All ${uploadResult.length} NFTs added successfully with no errors`);
                    }
                    navigate("/nfts");
                } else {
                    if (preUploadResult.errors.length > 0) {
                        const addInfo = preUploadResult.errors.map((error, index) => (
                            <div key={index}>
                                <h5>{error.sketchFabId}:</h5>
                                <small>{error.message}</small>
                            </div>
                        ));
                        showErrorMessage("Errors with the following IDs:", addInfo);
                    } else {
                        showErrorMessage("Error during upload");
                    }
                    return;
                }
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            if (err.error && err.error.data.message) {
                return showErrorMessage(err.error.data.message);
            }
            if (err.code) {
                ///Metamask error
                if (err.code === "ACTION_REJECTED") return showErrorMessage("You have rejected transaction");
                if (err.code === "UNPREDICTABLE_GAS_LIMIT")
                    return showErrorMessage("Gas limit cannot be predicted. Connected wallet is not an owner");
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/nfts">
                    <button>Back</button>
                </Link>
                <h1>Add NFTs</h1>
                <small>bulk upload</small>
            </div>
            <div className="subsection-body">
                <form onSubmit={handleFormSubmit} id="file-form">
                    <div className="input-group">
                        <input type="file" accept=".csv" onChange={handleFileChange} />
                        <label>Please select a csv file containing NFT data</label>
                    </div>
                    <button type="submit" disabled={!file} className="btn-submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MultiUpload;
