import { useState } from "react";
import { Link } from "react-router-dom";
import useDataContext from "../data/useDataContext";

import { formatNameString } from "../utils/helpers";
import IconButton from "../components/IconButton";

import { ReactComponent as EditIcon } from "../assets/icons/pen-to-square-solid.svg";
import { ReactComponent as EditPriceIcon } from "../assets/icons/dollar-sign-solid.svg";
import { ReactComponent as EnabledIcon } from "../assets/icons/toggle-on-solid.svg";
import { ReactComponent as DisabledIcon } from "../assets/icons/toggle-off-solid.svg";

const NFTDisplay = ({ data, perPage }) => {
    const { isOwner } = useDataContext();
    const [visible, setVisible] = useState(perPage);

    function showMoreItems() {
        setVisible((prevValue) => prevValue + perPage);
    }

    function isAvailable(nft) {
        if (!nft.available) return false;
        if (nft.maxSupply && nft.maxSupply > 0 && nft.maxSupply <= nft.totalSupply) return false;
        if (nft.endSale && nft.endSale > 0 && new Date().valueOf() / 1000 > nft.endSale) return false;
        return true;
    }

    return (
        <>
            <div>
                {data.slice(0, visible).map((nft) => {
                    return (
                        <div className="nft-container" key={nft._id}>
                            <div className="nft-main-info">
                                <div className="layered-cell">
                                    <h4>{nft.tokenId}</h4>
                                    <small>token id</small>
                                </div>
                                <img src={nft.thumbnail} alt={nft.title} />
                                <h4>{formatNameString(nft.title)}</h4>
                            </div>
                            <div className="layered-cell">
                                <h4>{nft.price.$numberDecimal} MATIC</h4>
                                <small>price</small>
                            </div>
                            <div className="layered-cell">
                                <h4>{nft.totalSupply}</h4>
                                <small>sales</small>
                            </div>
                            <div className="layered-cell">
                                {isAvailable(nft) ? (
                                    <>
                                        <EnabledIcon />
                                        <small>sale active</small>
                                    </>
                                ) : (
                                    <>
                                        <DisabledIcon />
                                        <small>inactive</small>
                                    </>
                                )}
                            </div>
                            <div className="buttons-container">
                                <Link to={`/nfts/edit-info/${nft._id}`} state={{ nftData: nft }}>
                                    <IconButton addClass={"btn-normal btn-no-bg"} icon={<EditIcon />} text="edit info" />
                                </Link>
                                {isOwner ? (
                                    <Link to={`/nfts/edit-sale/${nft._id}`} state={{ nftData: nft }}>
                                        <IconButton addClass={"btn-normal btn-no-bg"} icon={<EditPriceIcon />} text="edit sale" />
                                    </Link>
                                ) : null}
                            </div>
                        </div>
                    );
                })}
            </div>
            {visible < data.length && (
                <button className="btn" onClick={showMoreItems}>
                    Load More
                </button>
            )}
        </>
    );
};

export default NFTDisplay;
