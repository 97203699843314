import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDataContext from "../data/useDataContext";
import useBlockchainContext from "../data/useBlockchainContext";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sanitizePrice, sanitizeEndSale, sanitizeMaxSupply } from "../utils/sanitize";

const AddNft = () => {
    const navigate = useNavigate();
    const { addNFTToContract, getTokenSupply } = useBlockchainContext();
    const { manualAddNFT, deleteNFT, categories, tags, startProcessing, showSuccessMessage, showErrorMessage, setModalMessage } = useDataContext();
    const [endDate, setEndDate] = useState(new Date());
    const addNftForm = useRef();

    async function handleSubmit(event) {
        event.preventDefault();
        let dbResult = false;
        let blockResult = false;
        try {
            startProcessing("Updating Database");

            const title = addNftForm.current[0].value;
            const price = sanitizePrice(addNftForm.current[1].value);
            const description = addNftForm.current[2].value;
            const skCreator = addNftForm.current[3].value;
            const skAvatar = addNftForm.current[4].value;
            const thumbnail = addNftForm.current[5].value;
            const model = addNftForm.current[6].value;
            const categories = Array.from(addNftForm.current[7].options)
                .filter((o) => o.selected)
                .map((o) => o.value);
            const tags = Array.from(addNftForm.current[8].options)
                .filter((o) => o.selected)
                .map((o) => o.value);

            const useMaxSupply = addNftForm.current[10].checked;
            const maxSupply = useMaxSupply ? sanitizeMaxSupply(addNftForm.current[9].value) : undefined;
            const useEndSale = addNftForm.current[12].checked;
            const endSale = useEndSale ? sanitizeEndSale(endDate) : undefined;

            const tokenId = await getTokenSupply();

            const nftObject = {
                tokenId,
                title,
                price,
                description,
                skCreator,
                skAvatar,
                thumbnail,
                model,
                categories,
                tags,
                available: true,
                maxSupply,
                endSale,
            };

            dbResult = await manualAddNFT(nftObject);
            setModalMessage("Waiting for blockchain confirmation");
            blockResult = await addNFTToContract(price, maxSupply, endSale);
            if (blockResult) {
                if (blockResult !== tokenId) throw new Error("Token Ids are desynchronized. Please contact Admin");
                showSuccessMessage("NFT has been added successfully");
                navigate("/nfts");
            } else {
                showErrorMessage("Something went wrong");
            }
        } catch (err) {
            console.log(err);
            if (dbResult && !blockResult) {
                console.log(dbResult);
                const result = await deleteNFT(dbResult._id);
                if (!result) return showErrorMessage(`The NFT was not removed from database: ${err.response.data.message}`);
            }
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            if (err.code) {
                ///Metamask error
                if (err.code === "ACTION_REJECTED") return showErrorMessage("You have rejected transaction");
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/nfts">
                    <button>Back</button>
                </Link>
                <h1>Add NFT</h1>
                <small>manual</small>
            </div>
            <div className="subsection-body">
                <form ref={addNftForm}>
                    <div className="input-group">
                        <input type="text" id="title" />
                        <label htmlFor="title">Model title</label>
                    </div>
                    <div className="input-group">
                        <input type="number" id="price" min="0" />
                        <label htmlFor="price">Price in MATIC</label>
                    </div>
                    <div className="input-group">
                        <textarea id="description" />
                        <label htmlFor="description">Model description</label>
                    </div>
                    <div className="input-group">
                        <input type="text" id="creator" />
                        <label htmlFor="creator">Creator display name</label>
                    </div>
                    <div className="input-group">
                        <input type="url" id="avatar" />
                        <label htmlFor="avatar">Link to creator avatar</label>
                    </div>
                    <div className="input-group">
                        <input type="url" id="thumbnail" />
                        <label htmlFor="thumbnail">Link to thumbnail image</label>
                    </div>
                    <div className="input-group">
                        <input type="url" id="model" />
                        <label htmlFor="model">Link to 3d model preview</label>
                    </div>
                    <div className="input-group">
                        <select multiple id="categories">
                            {categories.map((cat) => (
                                <option value={cat._id} key={cat._id}>
                                    {cat.name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="category">Select categories (select multiple with CMD)</label>
                    </div>
                    <div className="input-group">
                        <select multiple id="tags">
                            {tags.map((tag) => (
                                <option value={tag._id} key={tag._id}>
                                    {tag.name}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="tags">Select tags (select multiple with CMD)</label>
                    </div>
                    <div className="divider">
                        <p>optional</p>
                    </div>
                    <div className="input-group">
                        <input type="number" id="maxSupply" min="1" />
                        <label htmlFor="maxSupply">Max # of NFTs</label>
                        <input type="checkbox" id="maxSupplyActive" />
                        <label htmlFor="maxSupplyActive"> &lt;- Activate</label>
                    </div>
                    <div className="input-group">
                        <DatePicker
                            selected={endDate}
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            minDate={new Date()}
                            showTimeSelect
                            id="endSale"
                            onChange={(date) => setEndDate(date)}
                        />
                        <label htmlFor="endSale">Sale End</label>
                        <input type="checkbox" id="endSaleActive" />
                        <label htmlFor="endSaleActive"> &lt;- Activate</label>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn-submit form-btn">
                        Add
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddNft;
