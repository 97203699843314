import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useDataContext from "../data/useDataContext";

const EditCategpry = () => {
    const { id } = useParams();
    const location = useLocation();
    const { categories, editCategory, findSingleById, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();
    const [category, setCategory] = useState(false);

    const categoryName = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setCategory(location.state.category);
        } else {
            const foundCategory = findSingleById(id, categories);
            setCategory(foundCategory);
        }
    }, [categories]);

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            if (id !== category._id) throw new Error("Ids are not the same");
            startProcessing("Updating database info");

            const result = await editCategory(category._id, categoryName.current.value);
            if (result) {
                showSuccessMessage("Category added successfully");
                navigate("/categories");
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/categories">
                    <button>Back</button>
                </Link>
                <h1>Edit Category</h1>
            </div>
            <div className="subsection-body">
                {category ? (
                    <form>
                        <div className="input-group">
                            <input type="text" id="categoryName" ref={categoryName} defaultValue={category.name} />
                            <label htmlFor="categoryName">Category Name</label>
                        </div>
                        <button type="submit" onClick={handleSubmit} className="btn-submit">
                            Save
                        </button>
                    </form>
                ) : (
                    <h1>Category not found</h1>
                )}
            </div>
        </div>
    );
};

export default EditCategpry;
