import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useDataContext from "../data/useDataContext";
import IconButton from "../components/IconButton";
import { ReactComponent as AddIcon } from "../assets/icons/plus-solid.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash-can-solid.svg";

const EditFooterMenu = () => {
    const { footerMenu, editFooterMenu, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        if (footerMenu.list?.length > 0) {
            setItemList(footerMenu.list);
        }
    }, [footerMenu]);

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
    };

    function addNewLink() {
        let updated = [...itemList];
        updated.push({ name: `Link ${itemList.length + 1}`, link: "Placeholder" });
        setItemList(updated);
    }

    function removeItem(index) {
        let updated = [...itemList];
        updated.splice(index, 1);
        setItemList(updated);
    }

    function handleNameChange(e, index) {
        e.preventDefault();
        let updated = [...itemList];
        updated[index].name = e.target.value;
        setItemList(updated);
    }

    function handleLinkChange(e, index) {
        e.preventDefault();
        let updated = [...itemList];
        updated[index].link = e.target.value;
        setItemList(updated);
    }

    async function saveFooterMenu(e) {
        e.preventDefault();
        try {
            startProcessing("Updating database information");
            await editFooterMenu(itemList);
            showSuccessMessage("Footer menu has been updated successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/settings">
                    <button>Back</button>
                </Link>
                <h1>Edit Footer Menu</h1>
                <IconButton addClass="btn-submit" text={"add new"} icon={<AddIcon />} customClickEvent={addNewLink} />
            </div>
            <div className="subsection-body">
                <div>
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                                    {itemList.map((item, index) => (
                                        <Draggable key={item.name} draggableId={item.name} index={index}>
                                            {(provided) => (
                                                <div
                                                    className="dragable-item-container"
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <h3>Link #{index + 1}</h3>
                                                    <div className="input-group">
                                                        <input type="text" defaultValue={item.name} onBlur={(e) => handleNameChange(e, index)} />
                                                        <label>Name</label>
                                                    </div>
                                                    <div className="input-group">
                                                        <input type="text" defaultValue={item.link} onBlur={(e) => handleLinkChange(e, index)} />
                                                        <label>Link</label>
                                                    </div>
                                                    <IconButton
                                                        icon={<DeleteIcon />}
                                                        addClass="btn-danger btn-no-bg"
                                                        customClickEvent={() => removeItem(index)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <button onClick={saveFooterMenu}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default EditFooterMenu;
