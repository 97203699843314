import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useDataContext from "../data/useDataContext";
import useBlockchainContext from "../data/useBlockchainContext";
import { sanitizePrice, sanitizeMaxSupply, sanitizeEndSale } from "../utils/sanitize";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditNFTPrice = () => {
    const { id } = useParams();
    const location = useLocation();
    const { editNFTSaleInfo, nfts, findSingleById, startProcessing, showSuccessMessage, showErrorMessage, setModalMessage } = useDataContext();
    const { editToken } = useBlockchainContext();

    const [nftData, setNftData] = useState(false);

    const [endDate, setEndDate] = useState(new Date());

    const editNFTForm = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        let nft;
        if (location.state) {
            nft = location.state.nftData;
        } else {
            nft = findSingleById(id, nfts);
        }
        setNftData(nft);
        if (nft.endSale && nft.endSale !== 0) {
            setEndDate(new Date(nft.endSale * 1000));
        }
    }, [nfts]);

    async function handleSubmit(event) {
        event.preventDefault();
        let dbResult = false;
        let blockResult = false;
        try {
            startProcessing("Updating Database");
            if (id !== nftData._id) throw new Error("Ids are not the same");

            const price = sanitizePrice(editNFTForm.current[0].value);
            const available = editNFTForm.current[1].checked;

            const useMaxSupply = editNFTForm.current[3].checked;
            const maxSupply = useMaxSupply ? sanitizeMaxSupply(editNFTForm.current[2].value) : undefined;
            const useEndSale = editNFTForm.current[5].checked;
            const endSale = useEndSale ? sanitizeEndSale(endDate) : undefined;

            const nftObject = {
                price,
                available,
                maxSupply,
                endSale,
            };

            console.log(nftObject);

            dbResult = await editNFTSaleInfo(id, nftObject);
            setModalMessage("Waiting for blockchain confirmation");
            blockResult = await editToken(nftData.tokenId, price, available, maxSupply, endSale);
            if (blockResult) {
                showSuccessMessage("NFT has been added successfully");
                navigate("/nfts");
            } else {
                showErrorMessage("Something went wrong");
            }
        } catch (err) {
            console.log(err);
            if (dbResult && !blockResult) {
                console.log(dbResult);
                const result = await editNFTSaleInfo(id, {
                    price: parseFloat(nftData.price.$numberDecimal),
                    available: nftData.available,
                    maxSupply: nftData.maxSupply || undefined,
                    endSale: nftData.endSale || undefined,
                });
                if (!result) return showErrorMessage(`The NFT was not reversed in database: ${err.response.data.message}`);
            }
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            if (err.code) {
                ///Metamask error
                if (err.code === "ACTION_REJECTED") return showErrorMessage("You have rejected transaction");
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/nfts">
                    <button>Back</button>
                </Link>
                <h1>Edit NFT Sale Info</h1>
            </div>
            <div className="subsection-body">
                {nftData ? (
                    <form ref={editNFTForm}>
                        <div className="input-group">
                            <input type="number" min={0} id="price" defaultValue={nftData.price.$numberDecimal} />
                            <label htmlFor="price">New price</label>
                        </div>
                        <div className="input-group">
                            <input type="checkbox" id="saleEnabled" defaultChecked={nftData.available} />
                            <label htmlFor="saleEnabled"> Sale Enabled</label>
                        </div>
                        <div className="divider">
                            <p>optional</p>
                        </div>
                        <div className="input-group">
                            <input type="number" id="maxSupply" min="1" defaultValue={nftData.maxSupply || 1} />
                            <label htmlFor="maxSupply">Max # of NFTs</label>
                            <input type="checkbox" id="maxSupplyActive" defaultChecked={nftData.maxSupply && nftData.maxSupply > 0} />
                            <label htmlFor="maxSupplyActive"> &lt;- Activate</label>
                        </div>
                        <div className="input-group">
                            <DatePicker
                                selected={endDate}
                                dateFormat={"dd/MM/yyyy HH:mm"}
                                minDate={new Date()}
                                showTimeSelect
                                id="endSale"
                                onChange={(date) => setEndDate(date)}
                            />
                            <label htmlFor="endSale">Sale End</label>
                            <input type="checkbox" id="endSaleActive" defaultChecked={nftData.endSale && nftData.endSale > 0} />
                            <label htmlFor="endSaleActive"> &lt;- Activate</label>
                        </div>
                        <button type="submit" onClick={handleSubmit} className="btn-submit">
                            Submit
                        </button>
                    </form>
                ) : (
                    <h1>NFT not found</h1>
                )}
            </div>
        </div>
    );
};

export default EditNFTPrice;
