import useDataContext from "../data/useDataContext";
import { useState } from "react";

import IconButton from "../components/IconButton";

import { ReactComponent as TrashIcon } from "../assets/icons/trash-can-solid.svg";
import { ReactComponent as UncheckedIcon } from "../assets/icons/square-regular.svg";
import { ReactComponent as CheckedIcon } from "../assets/icons/square-check-solid.svg";

const DownloadRequests = () => {
    const { downloadRequests, deleteDownloadRequest, flipDownloadRequestState } = useDataContext();

    const [visible, setVisible] = useState(10);

    function showMoreItems() {
        setVisible((prevValue) => prevValue + 10);
    }
    return (
        <div className="section" id="requests-section">
            <div className="section-header">
                <h1>Download Requests</h1>
            </div>
            <div>
                {downloadRequests.slice(0, visible).map((request) => {
                    return (
                        <div className="request-container" key={request._id}>
                            <div className="layered-cell">
                                <h4>{request.nft.tokenId}</h4>
                                <small>token id</small>
                            </div>
                            <div className="layered-cell request-info">
                                <h4>
                                    <span>email:</span> {request.emailAddress}
                                </h4>
                                <h4>
                                    <span>wallet:</span> {request.walletAddress}
                                </h4>
                            </div>
                            <IconButton
                                addClass={request.done ? "btn-normal btn-no-bg" : "btn-submit btn-no-bg"}
                                icon={request.done ? <CheckedIcon /> : <UncheckedIcon />}
                                text={request.done ? "done" : "processing"}
                                customClickEvent={() => flipDownloadRequestState(request._id, !request.done)}
                            />
                            <IconButton
                                addClass="btn-danger btn-no-bg"
                                icon={<TrashIcon />}
                                text="delete"
                                customClickEvent={() => deleteDownloadRequest(request._id)}
                            />
                        </div>
                    );
                })}
            </div>
            {visible < downloadRequests.length && (
                <button className="btn" onClick={showMoreItems}>
                    Load More
                </button>
            )}
        </div>
    );
};

export default DownloadRequests;
