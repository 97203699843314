import { REFRESH_URL } from "../data/constants";

export async function triggerRevalidation(tag) {
    try {
        const response = await fetch(`${REFRESH_URL}?tag=${encodeURIComponent(tag)}`);

        if (response.status === 200) {
            const result = await response.json();
            return true;
        } else {
            console.error("Failed to trigger revalidation");
            return false;
        }
    } catch (error) {
        console.error("Error triggering revalidation:", error);
        return false;
    }
}
