import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useDataContext from "../data/useDataContext";
import IconButton from "./IconButton";
import { ReactComponent as RefreshIcon } from "../assets/icons/rotate-solid.svg";

const EditNFTInfo = () => {
    const { id } = useParams();
    const location = useLocation();
    const { categories, tags, editNFTInfo, refreshNFTImages, nfts, findSingleById, startProcessing, showSuccessMessage, showErrorMessage } =
        useDataContext();
    const [nftData, setNftData] = useState(false);

    const editNFTForm = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setNftData(location.state.nftData);
        } else {
            const foundNFT = findSingleById(id, nfts);
            setNftData(foundNFT);
        }
    }, [nfts]);

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            startProcessing("Updating database info");
            if (id !== nftData._id) throw new Error("Ids are not the same");
            const title = editNFTForm.current[0].value;
            const description = editNFTForm.current[1].value;
            const skCreator = editNFTForm.current[2].value;
            const skAvatar = editNFTForm.current[3].value;
            const thumbnail = editNFTForm.current[4].value;
            const model = editNFTForm.current[5].value;
            const categories = Array.from(editNFTForm.current[6].options)
                .filter((o) => o.selected)
                .map((o) => o.value);
            const tags = Array.from(editNFTForm.current[7].options)
                .filter((o) => o.selected)
                .map((o) => o.value);

            const nftObject = {
                title,
                description,
                skCreator,
                skAvatar,
                thumbnail,
                model,
                categories,
                tags,
            };
            const result = await editNFTInfo(nftData._id, nftObject);
            if (result) {
                showSuccessMessage("NFT info has been changed successfully");
                navigate("/nfts");
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function handleRefresh(event) {
        event.preventDefault();
        try {
            startProcessing("Updating database info");
            const result = await refreshNFTImages(nftData._id);
            if (result) {
                showSuccessMessage("NFT images updated successfully");
                navigate("/nfts");
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/nfts">
                    <button>Back</button>
                </Link>
                <h1>Edit NFT Info</h1>
                {nftData.sketchFabId && (
                    <IconButton addClass="btn-submit" text={"refresh images"} icon={<RefreshIcon />} customClickEvent={handleRefresh} />
                )}
            </div>
            <div className="subsection-body">
                {nftData ? (
                    <form ref={editNFTForm}>
                        <div className="input-group">
                            <input type="text" id="title" defaultValue={nftData.title} />
                            <label htmlFor="uuid">Model title</label>
                        </div>
                        <div className="input-group">
                            <textarea id="description" defaultValue={nftData.description} />
                            <label htmlFor="description">Model description</label>
                        </div>
                        <div className="input-group">
                            <input type="text" id="creator" defaultValue={nftData.skCreator} />
                            <label htmlFor="creator">Creator display name</label>
                        </div>
                        <div className="input-group">
                            <input type="url" id="avatar" defaultValue={nftData.skAvatar} />
                            <label htmlFor="avatar">Link to creator avatar</label>
                        </div>
                        <div className="input-group">
                            <input type="url" id="thumbnail" defaultValue={nftData.thumbnail} />
                            <label htmlFor="thumbnail">Link to thumbnail image</label>
                        </div>
                        <div className="input-group">
                            <input type="url" id="model" defaultValue={nftData.model} />
                            <label htmlFor="model">Link to 3d model preview</label>
                        </div>
                        <div className="input-group">
                            <select multiple id="categories" defaultValue={nftData.categories}>
                                {categories.map((cat) => (
                                    <option value={cat._id} key={cat._id}>
                                        {cat.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="categories">Select categories (select multiple with CMD)</label>
                        </div>
                        <div className="input-group">
                            <select multiple id="tags" defaultValue={nftData.tags}>
                                {tags.map((tag) => (
                                    <option value={tag._id} key={tag._id}>
                                        {tag.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor="tags">Select tags (select multiple with CMD)</label>
                        </div>
                        <button type="submit" onClick={handleSubmit} className="btn-submit">
                            Submit
                        </button>
                    </form>
                ) : (
                    <h1>NFT not found</h1>
                )}
            </div>
        </div>
    );
};

export default EditNFTInfo;
