import { useState } from "react";
import useDataContext from "../data/useDataContext";
import useBlockchainContext from "../data/useBlockchainContext";

import { formatNameString } from "../utils/helpers";
import IconButton from "../components/IconButton";

import { ReactComponent as BanIcon } from "../assets/icons/ban-solid.svg";
import { ReactComponent as LinkIcon } from "../assets/icons/arrow-up-right-from-square-solid.svg";
import { ReactComponent as EnabledIcon } from "../assets/icons/toggle-on-solid.svg";
import { ReactComponent as DisabledIcon } from "../assets/icons/toggle-off-solid.svg";

import { WEBSITE_URL } from "../data/constants";
import { triggerRevalidation } from "../utils/revalidation";

const CommNFTDisplay = ({ data, perPage }) => {
    const { isOwner, handleFileDelete, deleteNFT, fetchCommunityNFTs, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();
    const { banToken } = useBlockchainContext();
    const [visible, setVisible] = useState(perPage);

    function showMoreItems() {
        setVisible((prevValue) => prevValue + perPage);
    }

    function isAvailable(nft) {
        if (!nft.available) return false;
        if (nft.maxSupply && nft.maxSupply > 0 && nft.maxSupply <= nft.totalSupply) return false;
        if (nft.endSale && nft.endSale > 0 && new Date().valueOf() / 1000 > nft.endSale) return false;
        return true;
    }

    async function handleBan(nftFull) {
        try {
            startProcessing("Please approve the transaction");
            const address = nftFull.collectionId.address;
            const slug = nftFull.collectionId.slug;
            const tokenId = nftFull.tokenId;
            const id = nftFull._id;
            const modelFilePath = `nfts/images/${nftFull.collectionId.slug}/${nftFull.model}`;
            const thumbnailFilePath = `nfts/models/${nftFull.collectionId.slug}/${nftFull.model}`;

            await banToken(address, slug, tokenId);
            startProcessing("Deleting files...");
            await handleFileDelete(modelFilePath);
            await handleFileDelete(thumbnailFilePath);
            await deleteNFT(id);
            fetchCommunityNFTs();
            triggerRevalidation("nft");
            showSuccessMessage("Succesfully banned NFT: " + nftFull.title);
        } catch (err) {
            showErrorMessage(err.message);
        }
    }

    return (
        <>
            <div>
                {data.slice(0, visible).map((nft) => {
                    return (
                        <div className="nft-container community" key={nft._id}>
                            <div className="nft-main-info">
                                <div className="layered-cell">
                                    <h4>{nft.tokenId}</h4>
                                    <small>token id</small>
                                </div>
                                <img src={nft.thumbnail} alt={nft.title} />
                                <h4>{formatNameString(nft.title)}</h4>
                            </div>
                            <div className="layered-cell">
                                <h4>{nft.price.$numberDecimal} MATIC</h4>
                                <small>price</small>
                            </div>
                            <div className="layered-cell">
                                <h4>{nft.totalSupply}</h4>
                                <small>sales</small>
                            </div>
                            <div className="layered-cell">
                                {isAvailable(nft) ? (
                                    <>
                                        <EnabledIcon />
                                        <small>sale active</small>
                                    </>
                                ) : (
                                    <>
                                        <DisabledIcon />
                                        <small>inactive</small>
                                    </>
                                )}
                            </div>
                            <div className="buttons-container">
                                <a href={`${WEBSITE_URL}/collections/community/${nft.tokenId}`} target="_blank">
                                    <IconButton addClass={"btn-normal btn-no-bg"} text={"view on site"} icon={<LinkIcon />} />
                                </a>
                                {isOwner ? (
                                    <IconButton
                                        customClickEvent={() => handleBan(nft)}
                                        addClass={"btn-danger btn-no-bg"}
                                        icon={<BanIcon />}
                                        text="ban token"
                                    />
                                ) : null}
                            </div>
                        </div>
                    );
                })}
            </div>
            {visible < data.length && (
                <button className="btn" onClick={showMoreItems}>
                    Load More
                </button>
            )}
        </>
    );
};

export default CommNFTDisplay;
