import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDataContext from "../data/useDataContext";
import useBlockchainContext from "../data/useBlockchainContext";

const AddAdminWallet = () => {
    const { addAdminWallet, startProcessing, showSuccessMessage, showErrorMessage, isOwner } = useDataContext();
    const { addEditor } = useBlockchainContext();
    const walletAddress = useRef();
    const navigate = useNavigate();

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            if (!isOwner) throw new Error("Not authorized");
            startProcessing("Updating blockchain info");
            await addEditor(walletAddress.current.value);
            startProcessing("Updating database info");
            await addAdminWallet(walletAddress.current.value);
            showSuccessMessage("Admin wallet added successfully");
            navigate("/settings");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            if (err.code) {
                ///Metamask error
                if (err.code === "ACTION_REJECTED") return showErrorMessage("You have rejected transaction");
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/settings">
                    <button>Back</button>
                </Link>
                <h1>Add Admin Wallet</h1>
            </div>
            <div className="subsection-body">
                <form>
                    <div className="input-group">
                        <input type="text" id="walletAddress" ref={walletAddress} />
                        <label htmlFor="walletAddress">New Admin Wallet Adress</label>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn-submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddAdminWallet;
