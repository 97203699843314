import { createContext, useEffect } from "react";
import { ethers, BigNumber } from "ethers";

import useDataContext from "./useDataContext";
import { sanitizeInputAddress } from "../utils/sanitize";

import { MARKETPLACE_ADDRESS, BSC_CHAIN, POLYGON_CHAIN } from "./constants";
import ContractABI from "../data/AlternoMarket.json";
import MarketplaceABI from "../data/AlternoDexArtMarketplaceV1.json";

const BlockchainContext = createContext({});

export const BlockchainProvider = ({ children }) => {
    const { contractAddress, account, isOwner, setIsOwner, setIsEditor, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();

    //// WALLET

    async function connectWallet() {
        if (!window.ethereum) throw new Error("Please install Metamask");
        const wallets = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        if (wallets.length > 0) {
            return wallets[0];
        } else {
            return false;
        }
    }

    async function handleChainSanitation(chain = undefined) {
        const chainId = chain || POLYGON_CHAIN;
        const currentChain = await window.ethereum.request({ method: "eth_chainId" });
        if (currentChain === chainId) return true;
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId }],
            });
            return true;
        } catch (err) {
            if (err.code === 4001) {
                window.alert("Please change your network to continue");
            } else {
                window.alert(err.message);
            }
            return false;
        }
    }

    async function assertConnection(chain = undefined) {
        const current = await connectWallet();
        if (current !== account) throw new Error(`Please switch to ${account} wallet`);
        await handleChainSanitation(chain);
    }

    async function signMessage(nonce) {
        const message = `Log in Alterno\nnonce: ${nonce}`;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const signedMessage = await signer.signMessage(message);
        return signedMessage;
    }

    async function checkIfEditor() {
        const contract = await getContractReadObject();
        const adminRole = await contract.DEFAULT_ADMIN_ROLE();
        const adminResult = await contract.hasRole(adminRole, account);
        if (adminResult) {
            setIsOwner(true);
            setIsEditor(true);
            return;
        }
        const editorRole = await contract.EDITOR();
        const editorResult = await contract.hasRole(editorRole, account);
        setIsEditor(editorResult);
    }

    useEffect(() => {
        async function prepare() {
            await handleChainSanitation();
            checkIfEditor();
        }

        if (account && contractAddress) {
            prepare();
        }
    }, [account, contractAddress]);

    //// WITHDRAW

    async function handleWithdraw() {
        try {
            startProcessing("Please approve the transaction...");
            await assertConnection();
            if (!isOwner) throw new Error("Not authorized");
            const contract = await getContractWriteObject();
            const withdrawReq = await contract.withdraw();
            const withdrawRes = await withdrawReq.wait();
            if (withdrawRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
            getContractBalance();
            showSuccessMessage("Succesfully withdrawn funds");
        } catch (err) {
            showErrorMessage(err.message);
            return;
        }
    }

    //// NFTS

    async function addNFTToContract(price, _maxSupply = 0, _endSale = 0) {
        await assertConnection();
        const maxSupply = parseInt(_maxSupply);
        if (maxSupply < 0) throw new Error("Max supply cannot be less than 0");
        const now = new Date().valueOf() / 1000;
        const endSale = parseInt(_endSale);
        if (endSale !== 0 && endSale < now) throw new Error("Sale end must be in the future");
        const contract = await getContractWriteObject();
        const newTokenId = await contract.currentTokenSupply();
        const addReq = await contract.addToken(ethers.utils.parseEther(price.toString()), maxSupply, endSale);
        const addRes = await addReq.wait();
        if (addRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return parseInt(newTokenId);
    }

    async function addMultipleNFTToContract(nfts) {
        await assertConnection();
        let startTokenId = nfts[0].tokenId;
        let endTokenId = 0;
        let prices = [];
        for (let i = 0; i < nfts.length; i++) {
            const nft = nfts[i];
            const price = parseFloat(nft.price).toString();
            prices.push(ethers.utils.parseEther(price));
            if (nft.tokenId < startTokenId) startTokenId = nft.tokenId;
            if (nft.tokenId > endTokenId) endTokenId = nft.tokenId;
        }
        const contract = await getContractWriteObject();
        const current = await contract.currentTokenSupply();
        if (endTokenId - startTokenId + 1 !== nfts.length || startTokenId !== parseInt(current)) throw new Error("TokenIDs are misplaced");
        const addReq = await contract.batchAddToken(prices);
        const addRes = await addReq.wait();
        if (addRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    async function editToken(tokenId, price, available, _maxSupply = 0, _endSale = 0) {
        await assertConnection();
        const contract = await getContractWriteObject();
        const editReq = await contract.editToken(tokenId, ethers.utils.parseEther(price.toString()), _maxSupply, _endSale, available);
        const editRes = await editReq.wait();
        if (editRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    async function editTokenSaleStatus(tokenId, state) {
        await assertConnection();
        const contract = await getContractWriteObject();
        const editReq = await contract.editTokenSaleStatus(tokenId, state);
        const editRes = await editReq.wait();
        if (editRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    //// USERS

    async function addEditor(address) {
        const wallet = sanitizeInputAddress(address);
        const contract = await getContractWriteObject();
        const editorRole = await contract.EDITOR();
        const addReq = await contract.grantRole(editorRole, wallet);
        const addRes = await addReq.wait();
        if (addRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    async function removeEditor(address) {
        const wallet = sanitizeInputAddress(address);
        const contract = await getContractWriteObject();
        const editorRole = await contract.EDITOR();
        const revokeReq = await contract.revokeRole(editorRole, wallet);
        const revokeRes = await revokeReq.wait();
        if (revokeRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    //// FEES

    async function setReferralFee(address, slug, bps) {
        if (!isOwner) throw new Error("Unauthorized");
        const contract = await getCollectionWriteObject(address, slug);
        const setFeeReq = await contract.seReferralFee(bps);
        const setFeeRes = await setFeeReq.wait();
        if (setFeeRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    async function setMarketplaceFee(address, slug, bps) {
        if (!isOwner) throw new Error("Unauthorized");
        const contract = await getCollectionWriteObject(address, slug);
        const setFeeReq = await contract.setMarketplaceFee(bps);
        const setFeeRes = await setFeeReq.wait();
        if (setFeeRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    //// BAN

    async function banToken(address, slug, tokenId) {
        if (!isOwner) throw new Error("Unauthorized");
        const contract = await getCollectionWriteObject(address, slug);
        const banReq = await contract.banToken(tokenId);
        const banRes = await banReq.wait();
        if (banRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
        return true;
    }

    //// INFORMATION

    async function getTokenSupply() {
        const contract = await getContractReadObject();
        const tokenSupply = await contract.currentTokenSupply();
        const supplyString = parseInt(tokenSupply);
        return supplyString;
    }

    async function getContractBalance() {
        if (!account) throw new Error("No wallet connected");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const balance = await provider.getBalance(contractAddress.address);
        const balanceString = ethers.utils.formatEther(balance);
        return balanceString;
    }

    async function getCurrentReferralFee(address, slug) {
        const contract = getCollectionReadObject(address, slug);
        const fee = await contract.referralFee();
        return fee.toString();
    }

    async function getCurrentMarketplaceFee(address, slug) {
        const contract = getCollectionReadObject(address, slug);
        const fee = await contract.marketplaceFee();
        return fee.toString();
    }

    //// DEXART MARKETPLACE

    async function getDexArtMarketplaceInfo() {
        try {
            const contract = await getDexArtMarketplaceContractRead();
            const creatorFee = await contract.creatorFee();
            const marketplaceFee = await contract.marketplaceFee();
            const maxFee = await contract.maxFee();
            return { creatorFee, marketplaceFee, maxFee };
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    async function setDexArtMarketplaceAletrnoFee(fee) {
        if (!isOwner) throw new Error("Not authorized");
        await assertConnection(BSC_CHAIN);
        const contract = await getDexArtMarketplaceContractWrite();
        const editReq = await contract.setMarketplaceFee(fee);
        const editRes = await editReq.wait();
        if (editRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
    }

    async function setDexArtMarketplaceMaxFee(fee) {
        if (!isOwner) throw new Error("Not authorized");
        await assertConnection(BSC_CHAIN);
        const contract = await getDexArtMarketplaceContractWrite();
        const editReq = await contract.setMaxFee(fee);
        const editRes = await editReq.wait();
        if (editRes.status !== 1) throw new Error("Transaction was reverted. Please try again");
    }

    //// HELPERS

    function getCollectionReadObject(address, slug) {
        if (!account) throw new Error("No wallet connected");
        const abi = require(`../data/${slug}.json`);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(address, abi, provider);
        return contract;
    }

    async function getCollectionWriteObject(address, slug) {
        if (!account) throw new Error("No wallet connected");
        const abi = require(`../data/${slug}.json`);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(address, abi, signer);
        return contract;
    }

    async function getContractReadObject() {
        if (!account) throw new Error("No wallet connected");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress.address, ContractABI.abi, provider);
        return contract;
    }

    async function getContractWriteObject() {
        if (!account) throw new Error("No wallet connected");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress.address, ContractABI.abi, signer);
        return contract;
    }

    async function getDexArtMarketplaceContractRead() {
        const provider = new ethers.providers.JsonRpcProvider(`https://go.getblock.io/${process.env.REACT_APP_GETBLOCK_RPCKEY}`);
        const contract = new ethers.Contract(MARKETPLACE_ADDRESS, MarketplaceABI.abi, provider);
        return contract;
    }

    async function getDexArtMarketplaceContractWrite() {
        if (!account) throw new Error("No wallet connected");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(MARKETPLACE_ADDRESS, MarketplaceABI.abi, signer);
        return contract;
    }

    return (
        <BlockchainContext.Provider
            value={{
                account,
                connectWallet,
                signMessage,
                addNFTToContract,
                addMultipleNFTToContract,
                editToken,
                editTokenSaleStatus,
                banToken,
                addEditor,
                removeEditor,
                getTokenSupply,
                getContractBalance,
                handleWithdraw,
                getCurrentReferralFee,
                getCurrentMarketplaceFee,
                setReferralFee,
                setMarketplaceFee,
                getDexArtMarketplaceInfo,
                setDexArtMarketplaceAletrnoFee,
                setDexArtMarketplaceMaxFee,
            }}
        >
            {children}
        </BlockchainContext.Provider>
    );
};

export default BlockchainContext;
