export const STATUS = {
    none: 0,
    success: 1,
    error: 2,
    processing: 3,
};

export const ORDER_OPTIONS = {
    old: {
        code: "OLD",
        name: "Oldest first",
    },
    new: {
        code: "NEW",
        name: "Newest first",
    },
    nameASC: {
        code: "NAME_ASC",
        name: "Name A-Z",
    },
    nameDESC: {
        code: "NAME_DESC",
        name: "Name Z-A",
    },
};

export const SALE_OPTIONS = {
    all: {
        code: "ALL",
        name: "All",
    },
    enabled: {
        code: "SALE",
        name: "Sale Enabled",
    },
    disabled: {
        code: "NOSALE",
        name: "Sale Disabled",
    },
};

export const POPULAR_OPTIONS = {
    all: {
        code: "ALL",
        name: "All",
    },
    popular: {
        code: "POP",
        name: "Popular Only",
    },
    regular: {
        code: "REG",
        name: "Regular Only",
    },
};

export const PRICE_OPTIONS = {
    all: {
        code: "ALL",
        name: "All",
    },
    free: {
        code: "FREE",
        name: "Free",
    },
    zeroFive: {
        code: "0_5",
        name: "0-5 MATIC",
        min: 0,
        max: 5,
    },
    fiveTen: {
        code: "5_10",
        name: "5-10 MATIC",
        min: 5,
        max: 10,
    },
    tenTwenty: {
        code: "10_20",
        name: "10-20 MATIC",
        min: 10,
        max: 20,
    },
    twentyPlus: {
        code: "20+",
        name: "20+ MATIC",
        min: 20,
    },
};

export const WEBSITE_URL = process.env.REACT_APP_LOCAL === "true" ? "http://localhost:3003" : "https://alternonft.com";
export const API_URL = process.env.REACT_APP_LOCAL === "true" ? "http://localhost:3000" : "https://api.alternonft.com";
export const REFRESH_URL = process.env.REACT_APP_LOCAL === "true" ? "http://localhost:3003/api/revalidate" : "https://alternonft.com/api/revalidate";

export const USER = process.env.REACT_APP_LOCAL === "true" ? "USER" : "2e40ad879e955201df4dedbf8d479a12";
export const PARTNER = process.env.REACT_APP_LOCAL === "true" ? "PARTNER" : "9ed8acdfad2eaad76069b0b8e256ea4e";
export const ADMIN = process.env.REACT_APP_LOCAL === "true" ? "ADMIN" : "73acd9a5972130b75066c82595a1fae3";

export const MARKETPLACE_ADDRESS =
    process.env.REACT_APP_LOCAL === "true" ? "0x0230f61FA23df17FEEE12A3346cC7Cd8A77798d7" : "0x1D4daF211f41Dad1b5182100A7f9703f6Ec73719";

export const POLYGON_CHAIN = process.env.REACT_APP_LOCAL === "true" ? "0x13881" : "0x89";
export const BSC_CHAIN = process.env.REACT_APP_LOCAL === "true" ? "0xaa36a7" : "0x38";
