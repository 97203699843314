import { ReactComponent as NFTIcon } from "../assets/icons/images-solid.svg";
import { ReactComponent as CategoriesIcon } from "../assets/icons/rectangle-list-solid.svg";
import { ReactComponent as TagsIcon } from "../assets/icons/tags-solid.svg";
import { ReactComponent as RequestsIcon } from "../assets/icons/cart-arrow-down-solid.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/circle-info-solid.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/gears-solid.svg";
import { ReactComponent as FAQIcon } from "../assets/icons/circle-question-regular.svg";
import { ReactComponent as PartnersIcon } from "../assets/icons/people-group-solid.svg";
import { ReactComponent as ComissionsIcon } from "../assets/icons/percent-solid.svg";
import { ReactComponent as CommunityNFTIcon } from "../assets/icons/users-viewfinder-solid.svg";

const sections = {
    list: [
        {
            name: "Data",
            links: [
                {
                    name: "NFTs",
                    path: "/nfts",
                    icon: <NFTIcon />,
                },
                {
                    name: "Categories",
                    path: "/categories",
                    icon: <CategoriesIcon />,
                },
                {
                    name: "Tags",
                    path: "/tags",
                    icon: <TagsIcon />,
                },
                {
                    name: "Download Requests",
                    path: "/requests",
                    icon: <RequestsIcon />,
                },
                {
                    name: "FAQ",
                    path: "/faq",
                    icon: <FAQIcon />,
                },
                {
                    name: "Partners",
                    path: "/partners",
                    icon: <PartnersIcon />,
                },
                {
                    name: "Comissions",
                    path: "/comissions",
                    icon: <ComissionsIcon />,
                },
                {
                    name: "Communtiy NFTs",
                    path: "/community-nfts",
                    icon: <CommunityNFTIcon />,
                },
            ],
        },
        {
            name: "State",
            links: [
                {
                    name: "Info",
                    path: "/info",
                    icon: <InfoIcon />,
                },
                {
                    name: "Settings",
                    path: "/settings",
                    icon: <SettingsIcon />,
                },
            ],
        },
    ],
};

export default sections;
