import "../styles/FAQ.css";
import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useDataContext from "../data/useDataContext";
import IconButton from "../components/IconButton";
import { ReactComponent as AddIcon } from "../assets/icons/plus-solid.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash-can-solid.svg";

const FAQ = () => {
    const { faqList, editFAQList, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        if (faqList.list?.length > 0) {
            setItemList(faqList.list);
        }
    }, [faqList]);

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
    };

    function addNewItem() {
        let updated = [...itemList];
        updated.push({ question: `Question ${itemList.length + 1}`, answer: "Placeholder" });
        setItemList(updated);
    }

    function removeItem(index) {
        let updated = [...itemList];
        updated.splice(index, 1);
        setItemList(updated);
    }

    function handleQuestionChange(e, index) {
        e.preventDefault();
        let updated = [...itemList];
        updated[index].question = e.target.value;
        setItemList(updated);
    }

    function handleAnswerChange(e, index) {
        e.preventDefault();
        let updated = [...itemList];
        updated[index].answer = e.target.value;
        setItemList(updated);
    }

    async function saveFAQList(e) {
        e.preventDefault();
        try {
            startProcessing("Updating database information");
            await editFAQList(itemList);
            showSuccessMessage("FAQs updated successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }
    return (
        <div className="section">
            <div className="section-header">
                <h1>FAQs</h1>
                <div>
                    <IconButton addClass="btn-submit" text={"add new"} icon={<AddIcon />} customClickEvent={addNewItem} />
                </div>
            </div>
            <div className="faq-body">
                <div id="faq-list">
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                                    {itemList.map((item, index) => (
                                        <Draggable key={item.question} draggableId={item.question} index={index}>
                                            {(provided) => (
                                                <div
                                                    className="dragable-item-container"
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <h3>Question #{index + 1}</h3>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            defaultValue={item.question}
                                                            onBlur={(e) => handleQuestionChange(e, index)}
                                                        />
                                                        <label>Question</label>
                                                    </div>
                                                    <div className="input-group">
                                                        <textarea defaultValue={item.answer} onBlur={(e) => handleAnswerChange(e, index)} />
                                                        <label>Answer</label>
                                                    </div>
                                                    <IconButton
                                                        icon={<DeleteIcon />}
                                                        addClass="btn-danger btn-no-bg"
                                                        customClickEvent={() => removeItem(index)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <button onClick={saveFAQList}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
