import "../styles/IconButton.css";

const IconButton = ({ addClass, icon, text, customClickEvent }) => {
    return (
        <button className={addClass ? addClass + " icon-btn" : "icon-btn"} onClick={customClickEvent ? customClickEvent : null}>
            {icon ? icon : null}
            {text ? <span>{text}</span> : null}
        </button>
    );
};

export default IconButton;
