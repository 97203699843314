import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDataContext from "../data/useDataContext";

const AddTag = () => {
    const { addTag, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();
    const tagName = useRef();
    const navigate = useNavigate();

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            startProcessing("Updating database info");
            const result = await addTag(tagName.current.value);
            if (result) {
                showSuccessMessage("Tag added successfully");
                navigate("/tags");
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/tags">
                    <button>Back</button>
                </Link>
                <h1>Add Tag</h1>
            </div>
            <div className="subsection-body">
                <form>
                    <div className="input-group">
                        <input type="text" id="tagName" ref={tagName} />
                        <label htmlFor="tagName">New Tag Name</label>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn-submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddTag;
