import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useDataContext from "../data/useDataContext";
import CategoriesDisplay from "../components/CategoriesDisplay";
import IconButton from "../components/IconButton";

import { ReactComponent as AddIcon } from "../assets/icons/plus-solid.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/filter-solid.svg";

import { ORDER_OPTIONS } from "../data/constants";

const Categories = () => {
    const { categories, sortCategoriesOrTags } = useDataContext();

    const [showSort, setShowSort] = useState(false);
    const [currentSorting, setCurrentSorting] = useState(ORDER_OPTIONS.old.code);
    const [displayValues, setDisplayValues] = useState(categories);

    function handleNameSortingChange(event) {
        const value = event.target.value;
        setCurrentSorting(value);
    }

    useEffect(() => {
        const sorted = sortCategoriesOrTags(currentSorting, categories);
        setDisplayValues(sorted);
    }, [categories, currentSorting]);

    return (
        <div className="section">
            <div className="section-header">
                <h1>Categories</h1>
                <div>
                    <Link to="/categories/add">
                        <IconButton addClass="btn-submit" text={"add new"} icon={<AddIcon />} />
                    </Link>
                    <IconButton
                        addClass={showSort ? "btn-submit btn-no-bg" : "btn-normal btn-no-bg"}
                        text={"sort"}
                        icon={<FilterIcon />}
                        customClickEvent={() => setShowSort(!showSort)}
                    />
                </div>
            </div>
            {showSort && (
                <div className="sort-container">
                    <div className="sort-input">
                        <select value={currentSorting} onChange={handleNameSortingChange}>
                            {Object.keys(ORDER_OPTIONS).map((key) => {
                                return (
                                    <option key={ORDER_OPTIONS[key].code} value={ORDER_OPTIONS[key].code}>
                                        {ORDER_OPTIONS[key].name}
                                    </option>
                                );
                            })}
                        </select>
                        <label>Order</label>
                    </div>
                </div>
            )}
            <CategoriesDisplay data={displayValues} perPage={50} />
        </div>
    );
};

export default Categories;
