import "../styles/Modal.css";
import { STATUS } from "../data/constants";
import Loading from "./Loading";
import { ReactComponent as SuccessIcon } from "../assets/icons/circle-check-solid.svg";
import { ReactComponent as ErrorIcon } from "../assets/icons/triangle-exclamation-solid.svg";

const Modal = ({ open, status, message, additionalInfo, onClose }) => {
    if (!open) return null;
    // if (status === STATUS.none) onClose();
    return (
        <>
            <div className="modal-overlay" />
            <div className="modal-container">
                {status === STATUS.processing && (
                    <>
                        <Loading />
                        <div>
                            <h4>Processing...</h4>
                            <small>{message ? message : "Do not close or refresh the page"}</small>
                        </div>
                    </>
                )}
                {status === STATUS.success && (
                    <>
                        <SuccessIcon className="icn-response icn-success" />
                        <div>
                            <h4>Success</h4>
                            <small>{message ? message : "You can close this message now"}</small>
                            {additionalInfo ? <div className="additional-info">{additionalInfo}</div> : null}
                        </div>
                        <button onClick={onClose}>Close</button>
                    </>
                )}
                {status === STATUS.error && (
                    <>
                        <ErrorIcon className="icn-response icn-danger" />
                        <div>
                            <h4>Error</h4>
                            <small>{message ? message : "There was an error processing your request"}</small>
                            {additionalInfo ? <div className="additional-info">{additionalInfo}</div> : null}
                        </div>
                        <button onClick={onClose}>Close</button>
                    </>
                )}
            </div>
        </>
    );
};

export default Modal;
