import "../styles/Statusbar.css";
import Logo from "../assets/images/logo.png";
import useDataContext from "../data/useDataContext";
import { formatAddressString } from "../utils/helpers";
import SearchBox from "../components/SearchBox";

const Statusbar = () => {
    const { loggedIn, account, hangleLogOut } = useDataContext();

    return (
        <div className="status-container">
            <div>
                <img src={Logo} alt="Alterno" />
            </div>
            <div>
                <SearchBox />
            </div>
            {loggedIn && <button onClick={hangleLogOut}>log out</button>}
            <div className="desktop-only">{loggedIn && <h5>Connected Wallet: {formatAddressString(account)}</h5>}</div>
        </div>
    );
};

export default Statusbar;
