import "./App.css";
import Statusbar from "./sections/Statusbar";
import Navigation from "./sections/Navigation";
import View from "./sections/View";
import sections from "./data/sectionsList";
import Modal from "./components/Modal";

import useDataContext from "./data/useDataContext";

function App() {
    const { showModal, closeModal, status, modalMessage, additionalInfo } = useDataContext();
    return (
        <div className="App">
            <Statusbar />
            <div className="main-container">
                <Navigation sectionsList={sections.list} />
                <View />
            </div>
            <Modal open={showModal} onClose={closeModal} status={status} message={modalMessage} additionalInfo={additionalInfo} />
        </div>
    );
}

export default App;
