import { createContext, useState, useEffect } from "react";
import axios from "axios";
import Papa from "papaparse";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { getAuth, signInWithCustomToken } from "firebase/auth";

import { sanitizeInputAddress, sanitizeInputString } from "../utils/sanitize";

import { API_URL, STATUS, ORDER_OPTIONS, SALE_OPTIONS, POPULAR_OPTIONS, PRICE_OPTIONS, ADMIN } from "./constants";
const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    //// LOGIN INFO
    const [loggedIn, setLoggedIn] = useState(false);
    const [account, setAccount] = useState(null);
    const [accessToken, setAccessToken] = useState("");
    const [isEditor, setIsEditor] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    //// DB INFO
    const [collections, setCollections] = useState([]);
    const [nfts, setNfts] = useState([]);
    const [communityNfts, setCommunityNfts] = useState(null);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [downloadRequests, setDownloadRequests] = useState([]);
    const [salesData, setSalesData] = useState(undefined);

    const [adminWallets, setAdminWallets] = useState([]);
    const [contractAddress, setContractAddress] = useState(null);
    const [cookiesText, setCookiesText] = useState({ header: "", text: "" });
    const [socials, setSocials] = useState([]);
    const [footerMenu, setFooterMenu] = useState([]);
    const [faqList, setFaqList] = useState([]);
    const [partnersList, setPartnersList] = useState([]);

    //// PROCESSING
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState(STATUS.none);
    const [modalMessage, setModalMessage] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState(null);

    //// FIREBASE

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const storage = getStorage(app);

    async function handleFileUpload(file, name, folder = null) {
        let filename = createFileName(name);
        if (folder) {
            const newFilename = `/${folder}/${filename}`;
            filename = newFilename;
        }
        const fileRef = ref(storage, filename);
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        return { filename, url };
    }

    function createFileName(name) {
        if (typeof name != "string") throw new Error("Incorrect input data");
        const lowercase = name.toLowerCase();
        const sanitized = lowercase.replace(/[^-a-zA-Z0-9 ]/g, "");
        const array = sanitized.split(" ");
        const filteredWords = array.filter((el) => el.length > 0);

        const date = new Date().toISOString();
        filteredWords.push(date);

        const filename = filteredWords.join("-");
        return filename;
    }

    async function handleFileDelete(filename) {
        const fileRef = ref(storage, filename);
        await deleteObject(fileRef);
        return;
    }

    //// AXIOS CLIENT SETTINGS

    const client = axios.create({
        baseURL: API_URL,
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
    });

    async function handleRefresh() {
        const refreshRes = await client.get("/login/refresh");
        if (refreshRes.data.role !== ADMIN) return;
        setAccessToken(`${refreshRes.data.accessToken}`);
        setAccount(refreshRes.data.wallet);
        await signInWithCustomToken(auth, refreshRes.data.googleToken);
        setLoggedIn(true);
        return refreshRes.data.accessToken;
    }

    useEffect(() => {
        const requestIntercept = client.interceptors.request.use(
            (config) => {
                if (!config.headers["Authorization"]) {
                    config.headers["Authorization"] = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = client.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await handleRefresh();
                    prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                    return client(prevRequest);
                }
                if (error?.response?.status === 403 && prevRequest?.sent) {
                    setLoggedIn(false);
                    setAccessToken("");
                    setAccount("");
                }
                return Promise.reject(error);
            }
        );

        // return () => {
        //     client.interceptors.request.eject(requestIntercept);
        //     client.interceptors.response.eject(responseIntercept);
        // };
    }, [accessToken, handleRefresh]);

    ////

    useEffect(() => {
        async function handleStartupRefresh() {
            if (localStorage.getItem("loggedIn")) {
                try {
                    let savedDate = Date.parse(localStorage.getItem("loggedIn"));
                    let currentDate = new Date();
                    if (currentDate.getTime() < savedDate) {
                        await handleRefresh();
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
        handleStartupRefresh();
    }, []);

    useEffect(() => {
        if (loggedIn) {
            fetchCollections();
            fetchCookiesText();
            fetchContractAddress();
            fetchSocials();
            fetchFooterMenu();
            fetchNFTs();
            fetchTags();
            fetchCategories();
            fetchAdmins();
            fetchDownloadRequests();
            fetchFAQList();
            fetchPartnersList();
            fetchTotalSales();
        }
    }, [loggedIn]);

    useEffect(() => {
        if (status === STATUS.none) return setShowModal(false);
        return setShowModal(true);
    }, [status]);

    /// AUTH

    async function getLoginNonce(address) {
        const sanitizedAddress = sanitizeInputAddress(address);
        const nonceResponse = await client.get(`${API_URL}/login/${sanitizedAddress}`);
        return nonceResponse.data.nonce;
    }

    async function handleLogin(address, message) {
        const verifyResponse = await client.post("/login/verify", { address, message });
        if (verifyResponse.data.role !== ADMIN) throw new Error("Unauthorized: Not an admin account");
        setAccount(verifyResponse.data.wallet);
        setAccessToken(verifyResponse.data.accessToken);
        await signInWithCustomToken(auth, verifyResponse.data.googleToken);
        setLoggedIn(true);
        let date = new Date();
        date.setDate(date.getDate() + 1); //add 1 day
        localStorage.setItem("loggedIn", date);
        return true;
    }

    function hangleLogOut() {
        setAccount(null);
        setLoggedIn(false);
        setAccessToken("");
        setIsEditor(false);
        setIsOwner(false);
        return;
    }

    /// DATABASE INTERACTIONS

    ////// NFTS

    async function fetchCollections() {
        try {
            const fetchResponse = await client.get("/admin/nfts/collections");
            const data = fetchResponse.data;
            setCollections(data);
            return data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function fetchNFTs() {
        try {
            const fetchResponse = await client.get("/admin/nfts");
            const sorted = fetchResponse.data.sort((a, b) => a.tokenId - b.tokenId);
            setNfts(sorted);
            return sorted;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function fetchCommunityNFTs() {
        try {
            const fetchResponse = await client.get("/admin/nfts/community");
            const data = fetchResponse.data;
            setCommunityNfts(data);
            return data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function manualAddNFT(nft) {
        const response = await client.post("/admin/nfts/manual", { nft });
        setNfts([...nfts, response.data.added]);
        return response.data.added;
    }

    async function autoAddNFT(nft) {
        const response = await client.post("/admin/nfts/auto", { nft });
        setNfts([...nfts, response.data.added]);
        fetchCategories();
        fetchTags();
        return response.data.added;
    }

    async function editNFTInfo(id, nft) {
        const response = await client.patch("/admin/nfts", { nft, id });
        const updatedNFTs = updateObjectInArray(response.data.changed, nfts);
        setNfts(updatedNFTs);
        return true;
    }

    async function editNFTSaleInfo(id, nft) {
        const response = await client.patch("/admin/nfts/sale", { nft, id });
        const updatedNFTs = updateObjectInArray(response.data.changed, nfts);
        setNfts(updatedNFTs);
        return true;
    }

    async function deleteNFT(id) {
        const response = await client.delete("/admin/nfts", { data: { id } });
        return true;
    }

    async function refreshNFTImages(id) {
        const response = await client.patch("/admin/nfts/images", { id });
        // return true;
        const updatedNFTs = updateObjectInArray(response.data.changed, nfts);
        setNfts(updatedNFTs);
        return true;
    }

    async function fetchTotalSales() {
        try {
            const response = await client.get("/admin/nfts/sales");
            const nftList = response.data;
            if (nftList.length === 0) setSalesData({ number: "0", sum: "0.0" });
            let sales = 0;
            let sum = 0;
            for (let i = 0; i < nftList.length; i++) {
                const nft = nftList[i];
                sales += nft.totalSupply;
                sum += parseFloat(nft.price.$numberDecimal) * nft.totalSupply;
            }
            setSalesData({ number: sales, sum });
        } catch (err) {
            showErrorMessage("Could not load sales data: " + err.message);
            return setSalesData(null);
        }
    }

    ////// CATEGORIES

    async function fetchCategories() {
        try {
            const fetchResponse = await client.get("/admin/categories");
            setCategories(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function addCategory(name) {
        const response = await client.post("admin/categories", { name });
        setCategories([...categories, response.data.created]);
        return true;
    }

    async function editCategory(id, name) {
        if (typeof id != "string") throw new Error("Incorrect id value");
        const response = await client.patch("admin/categories", { name, id });
        const updatedCategories = updateObjectInArray(response.data.edited, categories);
        setCategories(updatedCategories);
        return true;
    }

    async function deleteCategory(id) {
        try {
            if (typeof id != "string") throw new Error("Incorrect id value");
            startProcessing("Updating database info");
            const response = await client.delete("admin/categories", { data: { id } });
            const updatedCategories = deleteObjectInArray(response.data.deleted, categories);
            setCategories(updatedCategories);
            await fetchNFTs();
            showSuccessMessage("Category deleted successfully");
            return true;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    ////// TAGS

    async function fetchTags() {
        try {
            const fetchResponse = await client.get("/admin/tags");
            setTags(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function addTag(name) {
        const response = await client.post("admin/tags", { name });
        setTags([...tags, response.data.created]);
        return true;
    }

    async function editTag(id, name) {
        if (typeof id != "string") throw new Error("Incorrect id value");
        const response = await client.patch("admin/tags", { name, id });
        const updatedTags = updateObjectInArray(response.data.edited, tags);
        setTags(updatedTags);
        return true;
    }

    async function deleteTag(id) {
        try {
            if (typeof id != "string") throw new Error("Incorrect id value");
            startProcessing("Updating database info");

            const response = await client.delete("admin/tags", { data: { id } });
            const updatedTags = deleteObjectInArray(response.data.deleted, tags);
            setTags(updatedTags);
            await fetchNFTs();
            showSuccessMessage("Tag deleted successfully");

            return true;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    ////// DOWNLOAD REQUESTS

    async function fetchDownloadRequests() {
        try {
            const fetchResponse = await client.get("/admin/downloads");
            setDownloadRequests(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function deleteDownloadRequest(id) {
        try {
            if (typeof id != "string") throw new Error("Incorrect id value");
            startProcessing("Updating database info");
            const response = await client.delete("admin/downloads", { data: { id } });
            const updatedDonwloadRequests = deleteObjectInArray(response.data.deleted, downloadRequests);
            setDownloadRequests(updatedDonwloadRequests);
            showSuccessMessage("Request deleted successfully");

            return true;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function flipDownloadRequestState(id, state) {
        try {
            startProcessing("Updating database info");

            const response = await client.patch("admin/downloads", { state, id });
            const updatedDonwloadRequests = updateObjectInArray(response.data.changed, downloadRequests);
            setDownloadRequests(updatedDonwloadRequests);
            showSuccessMessage("Request updated successfully");

            return true;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    ////// FAQS

    async function fetchFAQList() {
        try {
            const fetchResponse = await client.get("/admin/settings/faq-info");
            setFaqList(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function editFAQList(list) {
        const response = await client.patch("admin/settings/faq-info", { id: faqList._id, list });
        setFaqList(response.data.edited);
        return true;
    }

    ////// PARTNERS LIST

    async function fetchPartnersList() {
        try {
            const fetchResponse = await client.get("/admin/settings/partners");
            setPartnersList(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function editPartnersList(list) {
        const response = await client.patch("admin/settings/partners", { id: partnersList._id, list });
        setPartnersList(response.data.edited);
        return true;
    }

    ////// SETTINGS

    async function fetchAdmins() {
        try {
            const fetchResponse = await client.get("/admin/settings/admins");
            setAdminWallets(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function addAdminWallet(walletAddress) {
        const address = sanitizeInputAddress(walletAddress);
        const response = await client.post("admin/settings/admins", { address });
        setAdminWallets([...adminWallets, response.data.added]);
    }

    async function deleteAdminWallet(id) {
        const response = await client.delete("admin/settings/admins", { data: { id } });
        const upated = deleteObjectInArray(response.data.deleted, adminWallets);
        setAdminWallets(upated);
    }

    async function fetchContractAddress() {
        try {
            const fetchResponse = await client.get("/admin/settings/contract");
            setContractAddress(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function fetchCookiesText() {
        try {
            const fetchResponse = await client.get("/admin/settings/cookies");
            setCookiesText(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function editCookiesText(id, newHeading, newText) {
        const heading = sanitizeInputString(newHeading);
        const text = sanitizeInputString(newText);
        const response = await client.patch("admin/settings/cookies", { id, heading, text });
        setCookiesText(response.data.edited);
        return true;
    }

    //// SOCIALS

    async function fetchSocials() {
        try {
            const fetchResponse = await client.get("/admin/settings/socials");
            setSocials(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function editSocialItem(id, link, active) {
        const response = await client.patch("admin/settings/socials", { id, link, active });
        const updatedSocials = updateObjectInArray(response.data.edited, socials);
        setSocials(updatedSocials);
        return true;
    }

    //// FOOTER MENU

    async function fetchFooterMenu() {
        try {
            const fetchResponse = await client.get("/admin/settings/footer-menu");
            setFooterMenu(fetchResponse.data);
            return fetchResponse.data;
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function editFooterMenu(list) {
        const response = await client.patch("admin/settings/footer-menu", { id: footerMenu._id, list });
        setFooterMenu(response.data.edited);
        return true;
    }

    //// SEARCH

    async function fetchSearchResults(query) {
        if (typeof query != "string") throw new Error("Incorrect query value");
        const response = await client.get(API_URL + "/admin/search/" + query);
        return response.data;
    }

    //// SORTING

    function sortCategoriesOrTags(sortOption, array) {
        if (sortOption === ORDER_OPTIONS.old.code) {
            return array;
        } else if (sortOption === ORDER_OPTIONS.new.code) {
            return [...array].reverse();
        } else if (sortOption === ORDER_OPTIONS.nameASC.code) {
            return [...array].sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        } else if (sortOption === ORDER_OPTIONS.nameDESC.code) {
            return [...array].sort((a, b) => {
                return b.name.localeCompare(a.name);
            });
        }
    }

    function sortNFTs(sortOptions) {
        let copy = [...nfts];
        const byCategory = sortByCategory(sortOptions.category, copy);
        const byTag = sortByTag(sortOptions.tag, byCategory);
        const byPrice = sortByPrice(sortOptions.price, byTag);
        const bySaleStatus = sortBySaleStatus(sortOptions.saleStatus, byPrice);
        const byPopularStatus = sortByPopularStatus(sortOptions.popularStatus, bySaleStatus);
        const byOrder = sortNFTsByOrder(sortOptions.order, byPopularStatus);
        return byOrder;
    }

    function sortByCategory(categoryOption, array) {
        if (array.length === 0 || categoryOption === "ALL") return array;
        return array.filter((nft) => nft.categories.includes(categoryOption));
    }

    function sortByTag(tagOption, array) {
        if (array.length === 0 || tagOption === "ALL") return array;
        return array.filter((nft) => nft.tags.includes(tagOption));
    }

    function sortByPrice(priceOption, array) {
        if (array.length === 0) return array;
        if (priceOption === PRICE_OPTIONS.all.code) {
            return array;
        } else if (priceOption === PRICE_OPTIONS.free.code) {
            return array.filter((nft) => parseFloat(nft.price.$numberDecimal) === 0);
        } else if (priceOption === PRICE_OPTIONS.zeroFive.code) {
            return array.filter((nft) => parseFloat(nft.price.$numberDecimal) >= 0 && parseFloat(nft.price.$numberDecimal) <= 5);
        } else if (priceOption === PRICE_OPTIONS.fiveTen.code) {
            return array.filter((nft) => parseFloat(nft.price.$numberDecimal) >= 5 && parseFloat(nft.price.$numberDecimal) <= 10);
        } else if (priceOption === PRICE_OPTIONS.tenTwenty.code) {
            return array.filter((nft) => parseFloat(nft.price.$numberDecimal) >= 10 && parseFloat(nft.price.$numberDecimal) <= 20);
        } else if (priceOption === PRICE_OPTIONS.twentyPlus.code) {
            return array.filter((nft) => parseFloat(nft.price.$numberDecimal) >= 20);
        }
    }

    function sortBySaleStatus(saleOption, array) {
        if (array.length === 0) return array;
        if (saleOption === SALE_OPTIONS.all.code) {
            return array;
        } else if (saleOption === SALE_OPTIONS.enabled.code) {
            return array.filter((nft) => nft.saleEnabled);
        } else if (saleOption === SALE_OPTIONS.disabled.code) {
            return array.filter((nft) => !nft.saleEnabled);
        }
    }

    function sortByPopularStatus(saleOption, array) {
        if (array.length === 0) return array;
        if (saleOption === POPULAR_OPTIONS.all.code) {
            return array;
        } else if (saleOption === POPULAR_OPTIONS.popular.code) {
            return array.filter((nft) => nft.popular);
        } else if (saleOption === POPULAR_OPTIONS.regular.code) {
            return array.filter((nft) => !nft.popular);
        }
    }

    function sortNFTsByOrder(orderOption, array) {
        if (array.length === 0) return array;
        if (orderOption === ORDER_OPTIONS.old.code) {
            return array.sort((a, b) => a.tokenId - b.tokenId);
        } else if (orderOption === ORDER_OPTIONS.new.code) {
            return array.sort((a, b) => b.tokenId - a.tokenId);
        } else if (orderOption === ORDER_OPTIONS.nameASC.code) {
            return array.sort((a, b) => {
                return a.title.localeCompare(b.title);
            });
        } else if (orderOption === ORDER_OPTIONS.nameDESC.code) {
            return array.sort((a, b) => {
                return b.title.localeCompare(a.title);
            });
        }
    }

    //// BULK UPLOAD

    function parseCSVFile(file) {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results, file) {
                    if (results.data.length === 0) reject(new Error("No nfts found to upload in the file"));
                    if (!results.data[0].sketchFabId || !results.data[0].price) reject(new Error("Incorrect file format"));
                    for (let i = 0; i < results.data.length; i++) {
                        const element = results.data[i];
                        element.sketchFabId = element.sketchFabId.trim();
                        element.price = parseFloat(element.price.trim());
                    }
                    resolve(results.data);
                },
                error(err, file) {
                    reject(err);
                },
            });
        });
    }

    async function preUploadNFTs(nfts, startTokenId) {
        const response = await client.post("/admin/nfts/preupload", { nfts, startTokenId });
        return response.data;
    }

    async function uploadNFTs(_nfts) {
        const response = await client.post("/admin/nfts/upload", { nfts: _nfts });
        setNfts([...nfts, ...response.data.added]);
        fetchCategories();
        fetchTags();
        return response.data.added;
    }

    //// PROCESSING

    function closeModal() {
        setStatus(STATUS.none);
        setModalMessage(null);
    }

    function showErrorMessage(message = null, addInfo = null) {
        setAdditionalInfo(addInfo);
        setModalMessage(message);
        setStatus(STATUS.error);
    }

    function startProcessing(message = null) {
        setModalMessage(message);
        setStatus(STATUS.processing);
    }

    function showSuccessMessage(message = null, addInfo = null) {
        setModalMessage(message);
        setAdditionalInfo(addInfo);
        setStatus(STATUS.success);
    }

    // HELPERS
    function updateObjectInArray(object, array) {
        let arrayCopy = [...array];
        const index = array.findIndex((v) => v._id === object._id);
        arrayCopy[index] = object;
        return arrayCopy;
    }

    function deleteObjectInArray(object, array) {
        let arrayCopy = [...array];
        const index = array.findIndex((v) => v._id === object._id);
        arrayCopy.splice(index, 1);
        return arrayCopy;
    }

    function findSingleById(id, array) {
        if (array.length === 0) return false;
        const index = array.findIndex((v) => v._id === id);
        if (index === -1) return false;
        return array[index];
    }

    return (
        <DataContext.Provider
            value={{
                ////FIREBASE
                handleFileUpload,
                handleFileDelete,
                ////NFTS
                collections,
                nfts,
                manualAddNFT,
                autoAddNFT,
                editNFTInfo,
                editNFTSaleInfo,
                deleteNFT,
                refreshNFTImages,
                salesData,
                ////Community nfts
                communityNfts,
                fetchCommunityNFTs,
                ////CATEGORIES
                categories,
                fetchCategories,
                addCategory,
                editCategory,
                deleteCategory,
                ////TAGS
                tags,
                fetchTags,
                addTag,
                editTag,
                deleteTag,
                ////REQUESTS
                downloadRequests,
                deleteDownloadRequest,
                flipDownloadRequestState,
                ////FAQS
                faqList,
                editFAQList,
                ////PARTNERS
                partnersList,
                editPartnersList,
                ///ADMIN WALLETS
                adminWallets,
                addAdminWallet,
                deleteAdminWallet,
                ///SETTINGS
                contractAddress,
                cookiesText,
                editCookiesText,
                socials,
                editSocialItem,
                footerMenu,
                editFooterMenu,
                ///LOGIN
                accessToken,
                setAccessToken,
                isOwner,
                setIsOwner,
                isEditor,
                setIsEditor,
                account,
                setAccount,
                loggedIn,
                setLoggedIn,
                getLoginNonce,
                handleLogin,
                hangleLogOut,
                ///PROCESSING
                showModal,
                closeModal,
                status,
                modalMessage,
                additionalInfo,
                startProcessing,
                showSuccessMessage,
                showErrorMessage,
                setModalMessage,
                ///SEARCH
                fetchSearchResults,
                ///SORT
                sortCategoriesOrTags,
                sortNFTs,
                ///BULK UPLOAD
                parseCSVFile,
                preUploadNFTs,
                uploadNFTs,
                ///HELPERS
                findSingleById,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
