export function sanitizeInputString(_input) {
    if (typeof _input != "string") throw new Error("Incorrect input format");
    let sanitized = _input.trim();
    return sanitized;
}

export function sanitizeInputAddress(_input) {
    if (typeof _input != "string") throw new Error("Incorrect input format");
    let address = _input.trim();
    const ADDR_REG = /^0x[a-fA-F0-9]{40}$/;
    if (ADDR_REG.test(address)) {
        return address.toLowerCase();
    } else {
        throw new Error(`Incorrect address format`);
    }
}

export function sanitizePrice(input) {
    const price = parseFloat(input);
    if (price < 0) throw new Error("Price can't be less than zero");
    return price;
}

export function sanitizeMaxSupply(input) {
    const output = parseInt(input);
    if (output < 1) throw new Error("Max supply can't be less than one");
    return output;
}

export function sanitizeEndSale(input) {
    if (new Date() >= input) throw new Error("Sale end must be in the future");
    const output = input.valueOf() / 1000;
    return output;
}
