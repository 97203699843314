import { useLocation, Navigate, Outlet } from "react-router-dom";
import useDataContext from "../data/useDataContext";

const RequireAuth = () => {
    const { loggedIn, account } = useDataContext();
    const location = useLocation();

    return account && loggedIn ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireAuth;
