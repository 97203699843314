import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useDataContext from "../data/useDataContext";

import NFTDisplay from "../components/NFTDisplay";
import IconButton from "../components/IconButton";

import { ReactComponent as AddIcon } from "../assets/icons/plus-solid.svg";
import { ReactComponent as AutoAdd } from "../assets/icons/plug-circle-plus-solid.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/filter-solid.svg";
import { ReactComponent as MultiAdd } from "../assets/icons/folder-plus-solid.svg";

import { ORDER_OPTIONS, SALE_OPTIONS, POPULAR_OPTIONS, PRICE_OPTIONS } from "../data/constants";

const NFTs = () => {
    const { nfts, categories, tags, sortNFTs, sortCategoriesOrTags, isEditor } = useDataContext();

    const [showSort, setShowSort] = useState(false);
    const [currentSorting, setCurrentSorting] = useState({
        saleStatus: SALE_OPTIONS.all.code,
        order: ORDER_OPTIONS.old.code,
        popularStatus: POPULAR_OPTIONS.all.code,
        price: PRICE_OPTIONS.all.code,
        category: "ALL",
        tag: "ALL",
    });
    const [displayValues, setDisplayValues] = useState(nfts);

    const [sortedTags, setSortedTags] = useState([]);
    const [sortedCategories, setSortedCategories] = useState([]);

    useEffect(() => {
        const _sortedTags = sortCategoriesOrTags("NAME_ASC", tags);
        setSortedTags(_sortedTags);
        const _sortedCategories = sortCategoriesOrTags("NAME_ASC", categories);
    }, [tags]);

    useEffect(() => {
        const _sortedCategories = sortCategoriesOrTags("NAME_ASC", categories);
        setSortedCategories(_sortedCategories);
    }, [categories]);

    useEffect(() => {
        const sorted = sortNFTs(currentSorting);
        setDisplayValues(sorted);
    }, [nfts, currentSorting]);

    function handleOrderChange(event) {
        const value = event.target.value;
        setCurrentSorting({ ...currentSorting, order: value });
    }

    function handleSaleStatusChange(event) {
        const value = event.target.value;
        setCurrentSorting({ ...currentSorting, saleStatus: value });
    }

    function handlePopularStatusChange(event) {
        const value = event.target.value;
        setCurrentSorting({ ...currentSorting, popularStatus: value });
    }

    function handlePriceChange(event) {
        const value = event.target.value;
        setCurrentSorting({ ...currentSorting, price: value });
    }

    function handleCategoryChange(event) {
        const value = event.target.value;
        setCurrentSorting({ ...currentSorting, category: value });
    }

    function handleTagChange(event) {
        const value = event.target.value;
        setCurrentSorting({ ...currentSorting, tag: value });
    }

    return (
        <div className="section" id="nfts-section">
            <div className="section-header">
                <h1>NFTs</h1>
                <div>
                    {isEditor ? (
                        <>
                            <Link to="/nfts/add-manual">
                                <IconButton addClass="btn-submit" text={"manual add"} icon={<AddIcon />} />
                            </Link>
                            <Link to="/nfts/add-auto">
                                <IconButton addClass="btn-submit" text={"auto add"} icon={<AutoAdd />} />
                            </Link>
                            <Link to="/nfts/upload">
                                <IconButton addClass="btn-submit" text={"upload many"} icon={<MultiAdd />} />
                            </Link>
                        </>
                    ) : null}
                    <IconButton
                        addClass={showSort ? "btn-submit btn-no-bg" : "btn-normal btn-no-bg"}
                        text={"sort"}
                        icon={<FilterIcon />}
                        customClickEvent={() => setShowSort(!showSort)}
                    />
                </div>
            </div>
            {showSort && (
                <div className="sort-container">
                    <div className="sort-input">
                        <select value={currentSorting.order} onChange={handleOrderChange}>
                            {Object.keys(ORDER_OPTIONS).map((key) => {
                                return (
                                    <option key={ORDER_OPTIONS[key].code} value={ORDER_OPTIONS[key].code}>
                                        {ORDER_OPTIONS[key].name}
                                    </option>
                                );
                            })}
                        </select>
                        <label>Order</label>
                    </div>
                    <div className="sort-input">
                        <select value={currentSorting.saleStatus} onChange={handleSaleStatusChange}>
                            {Object.keys(SALE_OPTIONS).map((key) => {
                                return (
                                    <option key={SALE_OPTIONS[key].code} value={SALE_OPTIONS[key].code}>
                                        {SALE_OPTIONS[key].name}
                                    </option>
                                );
                            })}
                        </select>
                        <label>Sale Status</label>
                    </div>
                    <div className="sort-input">
                        <select value={currentSorting.popularStatus} onChange={handlePopularStatusChange}>
                            {Object.keys(POPULAR_OPTIONS).map((key) => {
                                return (
                                    <option key={POPULAR_OPTIONS[key].code} value={POPULAR_OPTIONS[key].code}>
                                        {POPULAR_OPTIONS[key].name}
                                    </option>
                                );
                            })}
                        </select>
                        <label>Popular Status</label>
                    </div>
                    <div className="sort-input">
                        <select value={currentSorting.category} onChange={handleCategoryChange}>
                            <option key="default" value="ALL">
                                All
                            </option>
                            {sortedCategories.map((cat) => {
                                return (
                                    <option key={cat.slug} value={cat._id}>
                                        {cat.name}
                                    </option>
                                );
                            })}
                        </select>
                        <label>Category</label>
                    </div>
                    <div className="sort-input">
                        <select value={currentSorting.tag} onChange={handleTagChange}>
                            <option key="default" value="ALL">
                                All
                            </option>
                            {sortedTags.map((tag) => {
                                return (
                                    <option key={tag.slug} value={tag._id}>
                                        {tag.name}
                                    </option>
                                );
                            })}
                        </select>
                        <label>Tag</label>
                    </div>
                    <div className="sort-input">
                        <select value={currentSorting.price} onChange={handlePriceChange}>
                            {Object.keys(PRICE_OPTIONS).map((key) => {
                                return (
                                    <option key={PRICE_OPTIONS[key].code} value={PRICE_OPTIONS[key].code}>
                                        {PRICE_OPTIONS[key].name}
                                    </option>
                                );
                            })}
                        </select>
                        <label>Price</label>
                    </div>
                </div>
            )}
            <NFTDisplay data={displayValues} perPage={50} />
        </div>
    );
};

export default NFTs;
