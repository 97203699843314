import "../styles/Partners.css";

import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import useDataContext from "../data/useDataContext";
import IconButton from "../components/IconButton";
import { ReactComponent as AddIcon } from "../assets/icons/plus-solid.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash-can-solid.svg";

const Partners = () => {
    const { partnersList, editPartnersList, startProcessing, showSuccessMessage, showErrorMessage, handleFileUpload, handleFileDelete } =
        useDataContext();
    const [itemList, setItemList] = useState([]);
    const [firebaseChanges, setFirebaseChanges] = useState([]);
    const [filesToDelete, setFilesToDelete] = useState([]);

    useEffect(() => {
        console.log(partnersList);
        if (partnersList.list?.length > 0) {
            setItemList(partnersList.list);
            const newChanges = Array.apply(null, Array(partnersList.list.length)).map(function () {});
            setFirebaseChanges(newChanges);
        }
    }, [partnersList]);

    const handleFileChange = async (e, index) => {
        const file = e.target.files[0];
        if (!file) return;

        if (!file.type.startsWith("image/")) {
            alert("Please select an image file.");
            return;
        }

        if (file.size > 1024 * 1024) {
            alert("File size should be less than 1MB.");
            return;
        }

        const reader = new FileReader();
        const changeObject = { file, preview: undefined };

        await new Promise((resolve) => {
            reader.onload = (event) => {
                changeObject.preview = event.target.result;
                resolve();
            };
            reader.readAsDataURL(file);
        });

        const updatedChanges = [...firebaseChanges];
        updatedChanges[index] = changeObject;
        setFirebaseChanges(updatedChanges);
    };

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        let updatedList = [...itemList];
        let newChanges = [...firebaseChanges];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        const [reorderedChanges] = newChanges.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        newChanges.splice(droppedItem.destination.index, 0, reorderedChanges);
        setItemList(updatedList);
        setFirebaseChanges(newChanges);
    };

    function addNewItem() {
        let updated = [...itemList];
        let newChanges = [...firebaseChanges];
        updated.push({ name: `Partner ${itemList.length + 1}`, image: null, filename: null });
        newChanges.push(undefined);
        setItemList(updated);
        setFirebaseChanges(newChanges);
    }

    function removeItem(index) {
        let updated = [...itemList];
        let newChanges = [...firebaseChanges];
        const [removedItem] = updated.splice(index, 1);
        newChanges.splice(index, 1);
        if (removedItem.image) {
            let newDeletions = [...filesToDelete];
            newDeletions.push(removedItem.filename);
            setFilesToDelete(newDeletions);
        }
        setItemList(updated);
        setFirebaseChanges(newChanges);
    }

    function handleNameChange(e, index) {
        e.preventDefault();
        let updated = [...itemList];
        updated[index].name = e.target.value;
        setItemList(updated);
    }

    async function handleSave(e) {
        e.preventDefault();
        try {
            startProcessing("Updating database information");
            inputSanitation();
            const updatedList = await processChange(itemList, firebaseChanges, filesToDelete);
            await editPartnersList(updatedList);
            setFilesToDelete([]);
            showSuccessMessage("Partners updated successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    async function processChange(itemsList, uploadsList, filesToDelete) {
        const list = [...itemsList];
        const uploads = [...uploadsList];
        const deletions = [...filesToDelete];
        for (let index = 0; index < uploads.length; index++) {
            const upload = uploads[index];
            const item = list[index];
            if (upload) {
                const { url, filename } = await handleFileUpload(upload.file, item.name, "partners");
                item.filename = filename;
                item.image = url;
            }
        }
        if (deletions.length > 0) {
            for (let i = 0; i < deletions.length; i++) {
                const filename = deletions[i];
                await handleFileDelete(filename);
            }
        }
        return list;
    }

    function inputSanitation() {
        if (itemList.length !== firebaseChanges.length) throw new Error("Lists differ. Contact developer");
        for (let i = 0; i < itemList.length; i++) {
            const item = itemList[i];
            if (!item.image && !firebaseChanges[i]) throw new Error("Image missing for one of the partners");
        }
    }

    return (
        <div className="section">
            <div className="section-header">
                <h1>Partners</h1>
                <div>
                    <IconButton addClass="btn-submit" text={"add new"} icon={<AddIcon />} customClickEvent={addNewItem} />
                </div>
            </div>
            <div className="partners-body">
                <div id="partners-list">
                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => (
                                <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                                    {itemList.map((item, index) => (
                                        <Draggable key={item.name} draggableId={item.name} index={index}>
                                            {(provided) => (
                                                <div
                                                    className="dragable-item-container"
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                >
                                                    <h3>Partner #{index + 1}</h3>
                                                    <div className="input-group">
                                                        <input type="text" defaultValue={item.name} onBlur={(e) => handleNameChange(e, index)} />
                                                        <label>Name</label>
                                                    </div>
                                                    <div className="image-group">
                                                        {item.image ? (
                                                            <img src={item.image} alt={item.name} />
                                                        ) : firebaseChanges[index] ? (
                                                            <img src={firebaseChanges[index].preview} alt={item.name} />
                                                        ) : (
                                                            <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, index)} />
                                                        )}
                                                    </div>
                                                    <IconButton
                                                        icon={<DeleteIcon />}
                                                        addClass="btn-danger btn-no-bg"
                                                        customClickEvent={() => removeItem(index)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <button onClick={handleSave}>Save</button>
                    <button className="btn-submit" onClick={addNewItem}>
                        Add New
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Partners;
