import { useEffect, useState } from "react";
import useBlockchainContext from "../data/useBlockchainContext";
import useDataContext from "../data/useDataContext";

import { ReactComponent as WarningIcon } from "../assets/icons/triangle-exclamation-solid.svg";

const Information = () => {
    const { getTokenSupply, getContractBalance, handleWithdraw } = useBlockchainContext();

    const { nfts, contractAddress, downloadRequests, isOwner, salesData, loggedIn } = useDataContext();
    const [supply, setSupply] = useState("Loading...");
    const [balance, setBalance] = useState("Loading...");
    const [sales, setSales] = useState("Loading..");
    const [salesSum, setSalesSum] = useState("Loading...");
    const [pendingRequests, setPendingRequests] = useState("Loading...");

    useEffect(() => {
        if (contractAddress && loggedIn) {
            getTokenSupply().then((supply_) => setSupply(supply_));
            getContractBalance().then((balance_) => setBalance(balance_));
        }
    }, [contractAddress, loggedIn]);

    useEffect(() => {
        if (downloadRequests.length > 0) {
            const pending = downloadRequests.reduce((total, request) => (request.done ? total : total + 1), 0);
            setPendingRequests(pending);
        } else {
            setPendingRequests(0);
        }
    }, [downloadRequests]);

    useEffect(() => {
        if (salesData !== undefined) {
            if (salesData) {
                setSales(salesData.number);
                setSalesSum(salesData.sum);
            } else {
                setSales("Error");
                setSalesSum("Error");
            }
        }
    }, [salesData]);

    return (
        <>
            <div className="information-section">
                <div className="info-card">
                    <h4>NFTs Sold</h4>
                    <h1>{sales}</h1>
                </div>
                <div className="info-card">
                    <h4>Contract Balance</h4>
                    <h1>{balance} MATIC</h1>
                    {isOwner && balance !== "Loading..." && parseFloat(balance) > 0 && <button onClick={handleWithdraw}>Withdraw</button>}
                </div>
                <div className="info-card">
                    <h4>Total Sales</h4>
                    <h1>{salesSum} MATIC</h1>
                </div>
                <div className="info-card card-double">
                    {nfts.length != supply && <WarningIcon className="icn icn-danger" />}
                    <h4>Total NFTs</h4>
                    <div>
                        <div>
                            <h2>{nfts.length}</h2>
                            <small>database</small>
                        </div>
                        <div>
                            <h2>{supply}</h2>
                            <small>blockchain</small>
                        </div>
                    </div>
                </div>
                <div className="info-card">
                    {pendingRequests > 0 && <WarningIcon className="icn icn-warning" />}
                    <h4>Pending Requests</h4>
                    <h1>{pendingRequests}</h1>
                </div>
            </div>
            <div></div>
        </>
    );
};

export default Information;
