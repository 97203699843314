import { useState } from "react";
import { Link } from "react-router-dom";

import useDataContext from "../data/useDataContext";
import IconButton from "../components/IconButton";

import { ReactComponent as TrashIcon } from "../assets/icons/trash-can-solid.svg";
import { ReactComponent as EditIcon } from "../assets/icons/pen-to-square-solid.svg";

const CategoriesDisplay = ({ data, perPage }) => {
    const { deleteCategory } = useDataContext();

    const [visible, setVisible] = useState(perPage);

    function showMoreItems() {
        setVisible((prevValue) => prevValue + perPage);
    }

    return (
        <>
            <ol>
                {data.slice(0, visible).map((cat, index) => {
                    return (
                        <li key={index} className="item-container">
                            <div>
                                <div className="item-name-container">
                                    <h3>. {cat.name}</h3>
                                </div>
                                <div className="item-info-container">
                                    <h5>
                                        slug: <span>{cat.slug}</span>
                                    </h5>
                                    <h5>
                                        id: <span>{cat._id}</span>
                                    </h5>
                                </div>
                            </div>
                            <div className="buttons-container">
                                <Link to={`/categories/edit/${cat._id}`} state={{ category: cat }}>
                                    <IconButton addClass={"btn-normal btn-no-bg"} icon={<EditIcon />} text="edit" />
                                </Link>
                                <IconButton
                                    addClass="btn-danger btn-no-bg"
                                    icon={<TrashIcon />}
                                    text="delete"
                                    customClickEvent={async () => await deleteCategory(cat._id)}
                                />
                            </div>
                        </li>
                    );
                })}
            </ol>
            {visible < data.length && (
                <button className="btn" onClick={showMoreItems}>
                    Load More
                </button>
            )}
        </>
    );
};

export default CategoriesDisplay;
