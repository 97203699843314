import useDataContext from "../data/useDataContext";
import { REFRESH_URL } from "../data/constants";
import IconButton from "./IconButton";
import { ReactComponent as ResfreshIcon } from "../assets/icons/rotate-solid.svg";
import { triggerRevalidation } from "../utils/revalidation";

const UpdateWebsite = () => {
    const { startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();

    async function handleRefresh(event) {
        event.preventDefault();
        try {
            startProcessing("Refreshing website");
            await triggerRevalidation("nft");
            await triggerRevalidation("collection");
            showSuccessMessage("Website refreshed successfully");
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }
    return (
        <section className="section">
            <div className="section-header">
                <h2>Update Website</h2>
            </div>
            <IconButton addClass={"update-button"} text={"UPDATE"} icon={<ResfreshIcon />} customClickEvent={handleRefresh} />
        </section>
    );
};

export default UpdateWebsite;
