import "../styles/View.css";

import { Routes, Route } from "react-router-dom";

//// NFTS
import NFTs from "../pages/NFTs";
import AddNft from "../components/AddNft";
import AddNftAuto from "../components/AddNftAuto";
import EditNFTInfo from "../components/EditNFTInfo";
import EditNFTPrice from "../components/EditNFTPrice";
import MultiUpload from "../components/MultiUpload";

//// CATEGORIES
import Categories from "../pages/Categories";
import AddCategory from "../components/AddCategory";
import EditCategpry from "../components/EditCategory";

//// TAGS
import Tags from "../pages/Tags";
import AddTag from "../components/AddTag";
import EditTag from "../components/EditTag";

import FAQ from "../pages/FAQ";
import Partners from "../pages/Partners";
import Comissions from "../pages/Comissions";
import UserNFTs from "../pages/UserNFTs";

//// SETTINGS
import Settings from "../pages/Settings";
import AddAdminWallet from "../components/AddAdminWallet";
import EditSocials from "../components/EditSocials";
import EditFooterMenu from "../components/EditFooterMenu";

//// OTHER
import Information from "../pages/Information";
import Login from "../pages/Login";
import RequireAuth from "../pages/RequireAuth";
import DownloadRequests from "../pages/DownloadRequests";
import SearchResults from "../pages/SearchResults";

const View = () => {
    return (
        <div className="view-container">
            <Routes>
                <Route path="/" element={<Login />} />
                <Route element={<RequireAuth />}>
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/categories/add" element={<AddCategory />} />
                    <Route path="/categories/edit/:id" element={<EditCategpry />} />
                    <Route path="/tags" element={<Tags />} />
                    <Route path="/tags/add" element={<AddTag />} />
                    <Route path="/tags/edit/:id" element={<EditTag />} />
                    <Route path="/nfts" element={<NFTs />} />
                    <Route path="/nfts/add-manual" element={<AddNft />} />
                    <Route path="/nfts/add-auto" element={<AddNftAuto />} />
                    <Route path="/nfts/upload" element={<MultiUpload />} />
                    <Route path="/nfts/edit-info/:id" element={<EditNFTInfo />} />
                    <Route path="/nfts/edit-sale/:id" element={<EditNFTPrice />} />
                    <Route path="/requests" element={<DownloadRequests />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/partners" element={<Partners />} />
                    <Route path="/comissions" element={<Comissions />} />
                    <Route path="/community-nfts" element={<UserNFTs />} />
                    <Route path="/info" element={<Information />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/settings/socials" element={<EditSocials />} />
                    <Route path="/settings/footer-menu" element={<EditFooterMenu />} />
                    <Route path="/wallets/add" element={<AddAdminWallet />} />
                    <Route path="/search/:query" element={<SearchResults />} />
                </Route>
                <Route path="*" element={<h1>Not found</h1>} />
            </Routes>
        </div>
    );
};

export default View;
