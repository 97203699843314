import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useDataContext from "../data/useDataContext";

const EditTag = () => {
    const { id } = useParams();
    const location = useLocation();
    const { tags, editTag, findSingleById, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();
    const [tag, setTag] = useState(false);

    const tagName = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setTag(location.state.tag);
        } else {
            const foundTag = findSingleById(id, tags);
            setTag(foundTag);
        }
    }, [tags]);

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            startProcessing("Updating database info");
            if (id !== tag._id) throw new Error("Ids are not the same");
            const result = await editTag(tag._id, tagName.current.value);
            if (result) {
                showSuccessMessage("Tag name changed successfully");
                navigate("/tags");
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }
    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/tags">
                    <button>Back</button>
                </Link>
                <h1>Edit Tag</h1>
            </div>
            <div className="subsection-body">
                <form>
                    <div className="input-group">
                        <input type="text" id="tagName" ref={tagName} defaultValue={tag.name} />
                        <label htmlFor="tagName">New Tag Name</label>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn-submit">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditTag;
