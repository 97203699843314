import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataContext from "../data/useDataContext";

import { ReactComponent as SearchIcon } from "../assets/icons/magnifying-glass-solid.svg";

import "../styles/SearchBox.css";

const SearchBox = () => {
    const navigate = useNavigate();

    const { nfts, categories, tags } = useDataContext();
    const [query, setQuery] = useState("");

    const handleInputChange = (event) => {
        const { value } = event.target;
        setQuery(value);
    };

    function handleSearchSubmit(event) {
        event.preventDefault();
        navigate(`/search/${query.toLowerCase()}`);
        setQuery("");
    }

    return (
        <form className="form-search" onSubmit={handleSearchSubmit}>
            <input type="text" placeholder="Search here" value={query} onChange={handleInputChange} />
            <button type="submit">
                <SearchIcon />
            </button>
        </form>
    );
};

export default SearchBox;
