import { useEffect } from "react";
import useDataContext from "../data/useDataContext";
import CommNFTDisplay from "../components/CommNFTDisplay";
import Loading from "../components/Loading";

const UserNFTs = () => {
    const { communityNfts, fetchCommunityNFTs } = useDataContext();

    useEffect(() => {
        if (!communityNfts) fetchCommunityNFTs();
    }, [communityNfts]);

    return (
        <div className="section" id="nfts-section">
            <div className="section-header">
                <h1>Community NFTs</h1>
            </div>
            {communityNfts ? <CommNFTDisplay data={communityNfts} perPage={50} /> : <Loading />}
        </div>
    );
};

export default UserNFTs;
