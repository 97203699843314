import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import useDataContext from "../data/useDataContext";

import NFTDisplay from "../components/NFTDisplay";
import CategoriesDisplay from "../components/CategoriesDisplay";
import TagsDisplay from "../components/TagsDisplay";
import Loading from "../components/Loading";

const SearchResults = () => {
    const { query } = useParams();
    const { fetchSearchResults, showErrorMessage } = useDataContext();
    const [results, setResults] = useState(null);

    useEffect(() => {
        try {
            fetchSearchResults(query).then((data) => setResults(data));
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }

        return () => {
            setResults([]);
        };
    }, [query]);
    return (
        <div className="section" id="nfts-section">
            <div className="section-header">
                <h1>Search Results</h1>
            </div>
            {results ? (
                <>
                    <h3 className="search-subheader">NFTs</h3>
                    {results.nfts && results.nfts.length > 0 ? <NFTDisplay data={results.nfts} perPage={50} /> : <small>Nothing found</small>}
                    <h3 className="search-subheader">Categories</h3>
                    {results.categories && results.categories.length > 0 ? (
                        <CategoriesDisplay data={results.categories} perPage={50} />
                    ) : (
                        <small>Nothing found</small>
                    )}
                    <h3 className="search-subheader">Tags</h3>
                    {results.tags && results.tags.length > 0 ? <TagsDisplay data={results.tags} perPage={50} /> : <small>Nothing found</small>}
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default SearchResults;
