import { Link, useLocation } from "react-router-dom";
import "../styles/Navigation.css";

const Navigation = ({ sectionsList }) => {
    const { pathname } = useLocation();
    return (
        <div className="nav-container">
            {sectionsList.map((section) => {
                return (
                    <div className="nav-section-container" key={section.name}>
                        <h3>{section.name}</h3>
                        {section.links.map((link) => {
                            return (
                                <Link to={link.path} className={pathname === link.path ? "nav-link active" : "nav-link"} key={link.path}>
                                    {link.icon ? link.icon : null}
                                    <small>{link.name}</small>
                                </Link>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default Navigation;
