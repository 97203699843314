import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDataContext from "../data/useDataContext";
import useBlockchainContext from "../data/useBlockchainContext";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { sanitizePrice, sanitizeMaxSupply, sanitizeEndSale } from "../utils/sanitize";

const AddNftAuto = () => {
    const navigate = useNavigate();
    const { addNFTToContract, getTokenSupply } = useBlockchainContext();
    const { autoAddNFT, deleteNFT, startProcessing, showSuccessMessage, showErrorMessage, setModalMessage } = useDataContext();
    const [endDate, setEndDate] = useState(new Date());
    const autoAddForm = useRef();

    async function handleSubmit(event) {
        event.preventDefault();
        let dbResult = false;
        let blockResult = false;

        try {
            startProcessing("Please sign Metamask transaction");
            const sketchFabId = autoAddForm.current[0].value;
            const price = sanitizePrice(autoAddForm.current[1].value);
            const tokenId = await getTokenSupply();

            const useMaxSupply = autoAddForm.current[3].checked;
            const maxSupply = useMaxSupply ? sanitizeMaxSupply(autoAddForm.current[2].value) : undefined;
            const useEndSale = autoAddForm.current[5].checked;
            const endSale = useEndSale ? sanitizeEndSale(endDate) : undefined;

            const nftObject = {
                sketchFabId,
                price,
                tokenId,
                maxSupply,
                endSale,
            };
            console.log(nftObject);

            dbResult = await autoAddNFT(nftObject);
            setModalMessage("Waiting for blockchain confirmation");

            blockResult = await addNFTToContract(price, maxSupply, endSale);
            if (blockResult) {
                showSuccessMessage("NFT has been added successfully");
                navigate("/nfts");
            } else {
                showErrorMessage("Something went wrong");
            }
        } catch (err) {
            console.log(err);
            if (dbResult && !blockResult) {
                console.log(dbResult);
                const result = await deleteNFT(dbResult._id);
                if (!result) return showErrorMessage(`The NFT was not removed from database: ${err.response.data.message}`);
            }
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            if (err.code) {
                ///Metamask error
                if (err.code === "ACTION_REJECTED") return showErrorMessage("You have rejected transaction");
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/nfts">
                    <button>Back</button>
                </Link>
                <h1>Add NFT</h1>
                <small>auto</small>
            </div>
            <div className="subsection-body">
                <form ref={autoAddForm}>
                    <div className="input-group">
                        <input type="text" id="sketchFabId" />
                        <label htmlFor="sketchFabId">Sketchfab UID</label>
                    </div>
                    <div className="input-group">
                        <input type="number" id="price" />
                        <label htmlFor="price">Price in MATIC</label>
                    </div>
                    <div className="divider">
                        <p>optional</p>
                    </div>
                    <div className="input-group">
                        <input type="number" id="maxSupply" min="1" defaultValue={1} />
                        <label htmlFor="maxSupply">Max # of NFTs</label>
                        <input type="checkbox" id="maxSupplyActive" defaultChecked={false} />
                        <label htmlFor="maxSupplyActive"> &lt;- Activate</label>
                    </div>
                    <div className="input-group">
                        <DatePicker
                            selected={endDate}
                            dateFormat={"dd/MM/yyyy HH:mm"}
                            minDate={new Date()}
                            showTimeSelect
                            id="endSale"
                            onChange={(date) => setEndDate(date)}
                        />
                        <label htmlFor="endSale">Sale End</label>
                        <input type="checkbox" id="endSaleActive" defaultChecked={false} />
                        <label htmlFor="endSaleActive"> &lt;- Activate</label>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn-submit">
                        Add
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddNftAuto;
