import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useDataContext from "../data/useDataContext";

const AddCategory = () => {
    const { addCategory, startProcessing, showSuccessMessage, showErrorMessage } = useDataContext();
    const categoryName = useRef();
    const navigate = useNavigate();

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            startProcessing("Updating database info");
            const result = await addCategory(categoryName.current.value);
            if (result) {
                showSuccessMessage("Category added successfully");
                navigate("/categories");
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                ///Axios error
                if (err.response.data.message) {
                    return showErrorMessage(err.response.data.message);
                }
            }
            showErrorMessage(err.message ? err.message : null);
        }
    }

    return (
        <div className="section">
            <div className="subsection-header">
                <Link to="/categories">
                    <button>Back</button>
                </Link>
                <h1>Add Category</h1>
            </div>
            <div className="subsection-body">
                <form>
                    <div className="input-group">
                        <input type="text" id="categoryName" ref={categoryName} />
                        <label htmlFor="categoryName">New Category</label>
                    </div>
                    <button type="submit" onClick={handleSubmit} className="btn-submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddCategory;
